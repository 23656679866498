import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/service/api.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SessionstorageService } from 'src/app/service/sessionstorage.service';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { GetCurrentLocationService } from 'src/app/service/get-current-location.service';
import { MapsAPILoader } from '@agm/core';
import { AuthenticationService } from 'src/app/authentication.service';
import { GetSetValueService } from 'src/app/service/get-set-value.service';
declare var google: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  hide = true;
  submitted: Boolean = false;
  rememberMe: boolean = false;
  loginError: any
  saveLocationData: any;

  constructor(private api: ApiService, private router: Router,
    private toastr: ToastrService,
    private sessionStorage: SessionstorageService,
    private location: GetCurrentLocationService,
    private authenticate: AuthenticationService,
    private mapsAPILoader: MapsAPILoader,
    private localLan: GetSetValueService,

  ) { }

  ngOnInit(): void {
    let rememberMe: any = localStorage.getItem('rememberMe')
    if (rememberMe) {
      rememberMe = JSON.parse(rememberMe);
      this.loginForm.patchValue({
        email: rememberMe.email,
        password: rememberMe.password
      })
      this.rememberMe = true;
    }


    this.location.getLocation().then((data: any) => {
      this.sessionStorage.setLocalData('location', data)
      this.saveLocationData = data
      this.getCountryISOCode(this.saveLocationData.lat, this.saveLocationData.lng)
    });

    this.language = this.localLan.getLocalLanguage();
    this.setLanguageData();
  }

  getCountryISOCode(latitude: number, longitude: number): void {
    this.mapsAPILoader.load().then(() => {
      const geocoder = new google.maps.Geocoder();
      const latlng = new google.maps.LatLng(latitude, longitude);
      const geocoderRequest = { location: latlng };

      geocoder.geocode(geocoderRequest, (results: any, status: any) => {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            const country = results[0].address_components.find(component => { 
              return component.types.includes('country');
            });

              // console.log(country,"******")
            if (country) {
              const countryISOCode = country.short_name;
              this.sessionStorage.setLocalData('countryISOCode', countryISOCode)
              // console.log('Country ISO Code:', countryISOCode);
            }
          }
        } else {
          console.error('Geocoder failed due to: ' + status);
        }
      });
    });
  }

  loginForm = new FormGroup({
    email: new FormControl('', Validators.required),
    password: new FormControl('', Validators.required),
    latitude: new FormControl('28.44', Validators.required),
    longitude: new FormControl('77.56', Validators.required)
  })

  get f() {
    return this.loginForm.controls
  }
  

loginClick() {
    if (this.rememberMe) {
      let remember = {
        email: this.loginForm.value.email,
        password: this.loginForm.value.password
      }
      localStorage.setItem('rememberMe', JSON.stringify(remember))
    }
    else {
      localStorage.removeItem('rememberMe')
    }
  this.submitted = true
    // console.log(this.loginForm.value)
    if (this.loginForm.invalid) {
      return
    }
 let data = this.loginForm.value
    this.api.post('login', data).subscribe({
      next: (value: any) => {
        this.sessionStorage.setLocalData('vendor-service', value.response)
        if (value?.response?.vendor_status == '0' && value?.response.is_otp_verified == true && value.response.profile_completion == '3') {
          this.router.navigateByUrl('/home/admin-aproval')
        }
        else if (value?.response?.vendor_status == '1' && value.response.profile_completion == '3' && value.response.is_otp_verified == true ) {
          const resp = {
            ...value.response,
            logedIn: true
          }
          this.authenticate.logIn = true;
          this.router.navigateByUrl('/home/dashboard')
          this.toastr.success(value.message)

          
        }

//sub vendore 
        // else if (value?.response?.vendor_status == '1' && value.response.profile_completion == '3' && value.response.is_otp_verified == true &&  value?.response?.isSubVendor == 1  ) {
        //   const resp = {
        //     ...value.response,
        //     logedIn: true
        //   }
        //   this.authenticate.logIn = true;
        //   if(value?.response?.moduleAccess?.is_booking_mgmt == true){
        //     this.router.navigateByUrl('/home/booking-mgmt')
        //    }

        //   else if(value?.response?.moduleAccess?.is_venue_mgmt == true){
        //     this.router.navigateByUrl('/home/venue-mgmt')
        //    }
        //    else if(value?.response?.moduleAccess?.is_sport_mgmt == true){
        //     this.router.navigateByUrl('/home/playground-management')
        //    }
        //    else if(value?.response?.moduleAccess?.is_offer_mgmt == true){
        //     this.router.navigateByUrl('/home/offer-mgmt')
        //    }
        

          
        // }
//============== end ==============
        else if (value?.response.is_otp_verified == false && value.response.profile_completion == '1' && (value?.response?.vendor_status == '0' || value?.response?.vendor_status == '1')) {
          this.router.navigateByUrl('/otp-verification');
          this.toastr.info('Your Account is created but Otp not verified')
        }
        else if (value.response.profile_completion == '1' && value?.response.is_otp_verified == true && (value?.response?.vendor_status == '0' || value?.response?.vendor_status == '1')) {
          this.router.navigateByUrl('/venue-details');
          this.toastr.info('First  Add Venue Details')
        }
        else if (value.response.profile_completion == '2' && value?.response.is_otp_verified == true && (value?.response?.vendor_status == '0' || value?.response?.vendor_status == '1')) {
          this.router.navigateByUrl('/profile-creation');
          this.toastr.info('First  Create  Profile')
        }
        else {
          this.router.navigateByUrl('/home/reject-documnet')
        }

      }, error: (err) => {

        this.loginError = err.error?.message
        if (this.loginError?.includes('Email doesn', 'Email', 'email', 'Invalid Email !!', 'invalid email !!')) {
          this.toastr.warning('Invalid credentials ')
        }
        else if (this.loginError?.includes('Please enter the correct password.', 'Password', 'password', 'Invalid password Email')) {
          this.toastr.warning('Invalid Password')
        }


      }
    })
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      // rest of your code
    }
  }

  isRememberMe: boolean = false


  language:any;
  showData:any


  languageEng = {
    Login: "Login",
    WelcomeBack: "Welcome Back!", 
    EmailID:"Email ID",
    EnterMailId :"Enter Mail Id ",
    PleaseEnterEmail:"Please enter email",
    Password:"Password",
    EnterPassword:"Enter Password",
    PleaseEnterPassword:"Please Enter Password",
    show:"show",
    hide:'hide',
    RememberMe: "Remember me",
    ForgotPassword:"Forgot Password",
    NewtoSportsbay:  "New to Sportsbay ?",
    SignUp:'Sign Up'
  };


  languageArabic = {
    Login: "تسجيل الدخول",
    WelcomeBack: "مرحبًا بك مجددًا!", 
    EmailID:"البريد الإلكتروني",
    EnterMailId :"أدخل البريد الإلكتروني",
    PleaseEnterEmail:"الرجاء إدخال البريد الإلكتروني",
    Password:"كلمة المرور",
    EnterPassword:"أدخل كلمة المرور",
    PleaseEnterPassword: "الرجاء إدخال كلمة المرور",
    show:"عرض",
    hide:"يخفي",
    ForgotPassword:"نسيت كلمة المرور",
    RememberMe: "تذكرني",
    NewtoSportsbay:"هل أنت جديد في سبورتس باي؟",
    SignUp : "اشتراك",
  };

 



  setLanguageData() {
    debugger
    if (this.language === 'english') {
      this.showData = this.languageEng;

       

    } else {
      this.showData = this.languageArabic;

      var elements1 = document.querySelectorAll('.login-text');
     
      elements1.forEach((element1) => {
        element1.classList.add('rtl-direction');
      } );


    }
  }











}
