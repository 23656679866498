<app-authentication-header></app-authentication-header>
<section class="login d-flex align-items-center justify-content-center">
    <div class="login-box">
        <div class="row align-items-center">
            <div class="col-lg-6 login-text">
                <form [formGroup] = "loginForm">
                    <div class="fixed">

                        <h4>{{showData?.Login}}</h4>

                    </div>
                    <p class="font-blue welcome">{{showData?.WelcomeBack}}</p>
                    <div class="login-input ">
                        <label    class="required" for="email ">{{showData?.EmailID}}</label >
                        <mat-form-field class="example-full-width">
                            <input matInput placeholder="{{showData?.EnterMailId}}" formControlName = "email"   onpaste="return false;" ondrop="return false;" >
                        </mat-form-field>
                        <div *ngIf="submitted && f['email'].errors" class="text-danger">
                            <div *ngIf="f['email'].errors['required']">
                               {{showData?.PleaseEnterEmail}}
                            </div>
                        </div>
                    </div>
                    <div class="login-input">
                        <label  class="required" for="password">{{showData?.Password}}</label>
                        <mat-form-field appearance="fill">
                            <input matInput [type]="hide ? 'password' : 'text'" placeholder="{{showData?.EnterPassword}}" formControlName = "password"  onpaste="return false;" ondrop="return false;" >
                            <div class="my-show" matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                <span style="cursor: pointer;"> {{hide ?showData.show: showData.hide}} </span>
                            </div>
                        </mat-form-field>
                        <div *ngIf="submitted && f['password'].errors" class="text-danger">
                            <div *ngIf="f['password'].errors['required']">
                                {{showData?.PleaseEnterPassword}}
                            </div>
                        </div>
                    </div>
                    <div class="d-flex forget align-items-center justify-content-between">
                        <p class="sign1 my-check"><mat-checkbox class="example-margin" [(ngModel)]="rememberMe" [ngModelOptions]="{standalone: true}"></mat-checkbox>  {{showData?.RememberMe}}</p>
                        <!-- <mat-checkbox [(ngModel)]="rememberMe" [ngModelOptions]="{standalone: true}">Remember Me</mat-checkbox> -->

                        <p routerLink="/forget-password" class="point" style="    color: #91CC02;">{{showData?.ForgotPassword}}</p>


                    </div>
                    <!-- <div class="forget text-end" routerLink="/forget-password">
                    </div> -->
                    <div class="sub-log">
                        <button class="point" class="login-btn" (click) = "loginClick()" autofocus>{{showData?.Login}}</button>
                    </div>
                    <div class="sign text-center">
                        <p routerLink="/sign-up" class="point"><span class="font-black">{{showData?.NewtoSportsbay}} </span> <span class="font-green"> {{showData?.SignUp}}</span></p>
                    </div>

                </form>
            </div>
            <div class="col-lg-6">
                <img class="sports-img" src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/login_logo_with_bg.png" alt="">
            </div>
        </div>
    </div>
</section>
