import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private router:Router,
    private authenticate: AuthenticationService
  ){

  }
canActivate(): boolean {
    const sessionData = sessionStorage.getItem('vendor-service');

    if (sessionData) {
      const userData = JSON.parse(sessionData);

      // Check if 'logedIn' is true in the session data
      if (userData._id) {
        return true;
      }
    }


    this.router.navigate(['/login']);
    return false;
  }


}
