import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { AsideComponent } from './aside/aside.component';
import { AuthenticationHeaderComponent } from './authentication-header/authentication-header.component';
import { MaterialModule } from '../material/material.module';
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [
    HeaderComponent,
    AsideComponent,
    AuthenticationHeaderComponent,
    

  ],
  imports: [
    CommonModule,
    MaterialModule,
    RouterModule
    

  ],
  exports: [
    HeaderComponent,
    AsideComponent,
    AuthenticationHeaderComponent,
    

  ]
})
export class SharedModule { }
