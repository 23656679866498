import { MapsAPILoader } from '@agm/core';
import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/service/api.service';
import { GetCurrentLocationService } from 'src/app/service/get-current-location.service';
import { GetSetValueService } from 'src/app/service/get-set-value.service';
import { SessionstorageService } from 'src/app/service/sessionstorage.service';
declare var google: any;

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.css'],
})
export class SignUpComponent {
  hide = true;
  hide1 = true;
  submitted: Boolean = false;
  signupRes: any;
  agree: any = 0;
  termAndUse: boolean = false;
  countryISOCode: any;
  countryISOCodeFromLocal: any;
  language: string;
  showData:any;

 
  getNumber(event: any) {}
  telInputObject(event: any) {}
  onCountryChange(event: any) {}

  checked = false;

  constructor(
    private router: Router,
    private api: ApiService,
    private toastr: ToastrService,
    private sessionStorage: SessionstorageService,
    private location: GetCurrentLocationService,
    private mapsAPILoader: MapsAPILoader,
    private localLan: GetSetValueService
  ) {}
  ngOnInit() {
    this.getCityList();
    this.location.getLocation().then((data: any) => {
      this.sessionStorage.setLocalData('location', data);
    });
    this.countryISOCodeFromLocal =
      this.sessionStorage.getLocalData('countryISOCode');

    this.language = this.localLan.getLocalLanguage();
    this.setLanguageData();

  }

  getCountryISOCode(latitude: number, longitude: number): void {
    this.mapsAPILoader.load().then(() => {
      const geocoder = new google.maps.Geocoder();
      const latlng = new google.maps.LatLng(latitude, longitude);
      const geocoderRequest = { location: latlng };

      geocoder.geocode(geocoderRequest, (results: any, status: any) => {
        if (status === google.maps.GeocoderStatus.OK) {
          if (results[0]) {
            const country = results[0].address_components.find((component) => {
              return component.types.includes('country');
            });

            if (country) {
              this.countryISOCode = country.short_name;
              console.log('Country ISO Code:', this.countryISOCode);
            }
          }
        } else {
          console.error('Geocoder failed due to: ' + status);
        }
      });
    });
  }

  signUpForm = new FormGroup({
    bussiness_name: new FormControl('', Validators.required),
    country_code: new FormControl('+91', Validators.required),
    mobile_number: new FormControl('', Validators.required),
    // email: new FormControl('',Validators.required),
    email: new FormControl('', [
      Validators.required,
      Validators.pattern('^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$'),
    ]),
    password: new FormControl('', [
      Validators.required,
      Validators.pattern(
        /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).{8,16}$/
      ),
    ]),
    lat: new FormControl('28.77', Validators.required),
    long: new FormControl('77.33', Validators.required),
    device_type: new FormControl('3', Validators.required),
    device_token: new FormControl('webToken', Validators.required),
    city: new FormControl('', Validators.required),
  });

  // routerLink="/otp-verification"
  hasError(event: any) {
    console.log(event);
  }

  countryChange(event: any) {
    console.log(event);
    this.signUpForm.patchValue({
      country_code: `+${event.dialCode}`,
    });
  }

  getMobileNumber(event: any) {
    console.log(event.target.value);
    this.signUpForm.patchValue({
      mobile_number: event.target.value,
    });
  }
  get a() {
    return this.signUpForm.controls;
  }

  isChecked(status: MatCheckboxChange) {
    if (status.checked == true) {
      this.termAndUse = true;
    } else {
      this.termAndUse = false;
    }
  }
  signUp() {
    this.submitted = true;
    if (this.signUpForm.invalid) {
      return;
    }

    if (this.termAndUse != true) {
      // this.toastr.error("Please  accept terms and conditions")
      return;
    }
    let data = this.signUpForm.value;
    this.api.post('create_account', data).subscribe({
      next: (value: any) => {
        this.signupRes = value.response;
        // this.toastr.success("Please verify OTP.")
        this.sessionStorage.setLocalData('vendor-service', this.signupRes);
        this.router.navigateByUrl('/otp-verification');
      },
      error: (err: any) => {
        console.log(err);
        this.toastr.error(err.error.message);
      },
    });
  }
  getCityListData: any;
  getCityList() {
    this.api.get('get_city').subscribe({
      next: (res: any) => {
        this.getCityListData = res['response'];
        console.log(this.getCityListData, ':::::::');
      },
      error(err) {
        console.log(err);
      },
    });
  }

  onKeyPress(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.keyCode);
    const pattern = /^[a-zA-Z\s]*$/;
    // pattern=".*[^A-Za-z ].*"

    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  onKeyPress1(event: KeyboardEvent) {
    if (event.key === ' ') {
      event.preventDefault();
    }
  }

  onKeyPress2(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.keyCode);
    const pattern = /^[a-z0-9\.-@_s]/;

    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      // rest of your code
    }
  }

  languageEng = {
    CreateAccount: 'Create Account',
    BasicInfo: 'Basic Info',
    BusinessName: 'Business Name',
    EnterYourBussinessName: 'Enter Your Bussiness Name',
    EmailID: 'Email ID',
    EnterEmailId: 'Enter Email Id',
    MobileNumber: 'Mobile Number',
    EnterMobileNumber: 'Enter Mobile Number',
    City: 'City',
    SelectCity: 'Select City',
    
    CreatePassword : 'Create Password',

    SetPassword: 'Set Password',
    EnterPassword: 'Enter Password',
    Show: 'show',
    hide:'hide',

    Iagreeto: 'I agree to',
    TermConditions: 'Term & Conditions',
    and: 'and',
    PrivacyPolicyofthe: 'Privacy Policy of the',
    app:"App",
    SignUp: 'Sign Up',
    BusinessNameisrequired: 'Business Name is required',
    PleaseEnterEmailID: 'Please Enter Email ID',
    MobileNumberisrequired: 'Mobile Number is required',
    Cityisrequired: 'City is required',
    PleaseEnterPassword: 'Please Enter Password',
    AlreadyaSportsbay: 'Already a Sportsbay?',
    Login:'Login',
    PleaseEnteraValidEmailID:'Please Enter a Valid Email ID',
    PleaseacceptAllthetemscondition:'Please accept All the tems & condition',
    PasswordMust:'Password must be alphabet special  character & numeric at least 1 uppercase value with minimum 8 digit'
  };

  languageArabic = {
    CreateAccount: 'إنشاء حساب',
    BasicInfo: 'المعلومات الأساسية',
    BusinessName: 'اسم المؤسسة',
    EnterYourBussinessName: 'أدخل اسم المؤسسة',
    EmailID: 'البريد الإلكتروني',
    EnterEmailId: 'أدخل البريد الإلكتروني',
    MobileNumber: 'رقم الهاتف',
    EnterMobileNumber: 'أدخل رقم الهاتف',
    City: 'المدينة',
    SelectCity: 'اختر المدينة',
    SetPassword: 'تعيين كلمة مرور',
    EnterPassword: 'أدخل كلمة المرور',
    Show: 'عرض',
    hide:'يخفي',
    CreatePassword : 'إنشاء كلمة المرور',
    Iagreeto: 'أنا أوافق على',
    TermConditions: 'الأحكام والشروط',
    and: 'و',
    PrivacyPolicyofthe:'سياسة الخصوصية الخاصة بـ',
    app:'برنامج',
    SignUp: 'إنشاء حساب',
    BusinessNameisrequired: 'اسم المؤسسة مطلوب',
    PleaseEnterEmailID: 'يرجى إدخال البريد الإلكتروني',
    MobileNumberisrequired: 'رقم الهاتف مطلوب',
    Cityisrequired: 'اسم المدينة مطلوب',
    PleaseEnterPassword: 'يرجى إدخال كلمة المرور',
    AlreadyaSportsbay: 'بالفعل سبورتسباي؟',
    Login:'تسجيل الدخول',
    PleaseEnteraValidEmailID:'الرجاء إدخال معرف بريد إلكتروني صالح',
    PleaseacceptAllthetemscondition:'يرجى قبول كافة الشروط والأحكام',
    PasswordMust:'يجب أن تكون كلمة المرور عبارة عن حرف أبجدي خاص ورقم على الأقل بقيمة حرف كبير واحد مع 8 أرقام على الأقل.'

  };

  setLanguageData() {
    if (this.language === 'english') {
      this.showData = this.languageEng;
    } else {
      this.showData = this.languageArabic;
      var element1 = document.querySelectorAll('.login-text');     
      element1.forEach((element1) => {
        element1.classList.add('rtl-direction');
      });
    }
  }
}
