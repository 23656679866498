import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { OtpComponent } from './otp/otp.component';
import { SignUpComponent } from './sign-up/sign-up.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { ProfileCreationComponent } from './profile-creation/profile-creation.component';
import { VerifyOtpComponent } from './verify-otp/verify-otp.component';
import { VenueDetailsComponent } from './venue-details/venue-details.component';
import { AdminApprovalComponent } from './admin-approval/admin-approval.component';
import { DocumentUplodeComponent } from './document-uplode/document-uplode.component';
import { SettingLogoutComponent } from './setting-logout/setting-logout.component';


const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'forget-password', component: ForgetPasswordComponent },
  { path: 'otp-verification', component: OtpComponent },
  { path: 'sign-up', component:SignUpComponent },
  { path: 'reset-password/:token', component:ResetPasswordComponent },
  { path: 'profile-creation', component: ProfileCreationComponent },
  { path: 'verify-otp-verification', component: VerifyOtpComponent },
  {path:'venue-details',component:VenueDetailsComponent},
  {path:'admin-aproval',component:AdminApprovalComponent},
  {path:'reject-documnet',component:DocumentUplodeComponent},
  {path:'setting-logout',component:SettingLogoutComponent}


];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
  ],
  exports: [RouterModule]
})
export class AuthenticationRoutingModule { }
