<div class="d-flex justify-content-between align-items-center auth-header">
    <div class="logo">
        <img src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/header_logo_white.png" alt="">
        
    </div>
    <div>
      <img
      class="img-fluid"
      [matMenuTriggerFor]="menu"
      src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/hdr_language.png"
      alt=""
    />
    <mat-menu class="lang-menu" #menu="matMenu">
      <button
        [ngClass]="{ selected: language === 'english' }"
        (click)="changeLanguage('english')"
        mat-menu-item
      >
        English
      </button>

      <button
        [ngClass]="{ selected: language === 'arabic' }"
        (click)="changeLanguage('arabic')"
        mat-menu-item
      >
        اَلْعَرَبِيَّةُ
      </button>
    </mat-menu>
    </div>
    

</div>