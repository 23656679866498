import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  patch(arg0: string, data: { user_id: any; isBlocked: string; }) {
    throw new Error('Method not implemented.');
  }
  countries = [
    {
        "name": "Afghanistan",
        "alpha2Code": "AF",
        "alpha3Code": "AFG",
        "flag": "https://upload.wikimedia.org/wikipedia/commons/5/5c/Flag_of_the_Taliban.svg",
        "callingCodes": [
            "93"
        ]
    },
    {
        "name": "Åland Islands",
        "alpha2Code": "AX",
        "alpha3Code": "ALA",
        "flag": "https://flagcdn.com/ax.svg",
        "callingCodes": [
            "358"
        ]
    },
    {
        "name": "Albania",
        "alpha2Code": "AL",
        "alpha3Code": "ALB",
        "flag": "https://flagcdn.com/al.svg",
        "callingCodes": [
            "355"
        ]
    },
    {
        "name": "Algeria",
        "alpha2Code": "DZ",
        "alpha3Code": "DZA",
        "flag": "https://flagcdn.com/dz.svg",
        "callingCodes": [
            "213"
        ]
    },
    {
        "name": "American Samoa",
        "alpha2Code": "AS",
        "alpha3Code": "ASM",
        "flag": "https://flagcdn.com/as.svg",
        "callingCodes": [
            "1"
        ]
    },
    {
        "name": "Andorra",
        "alpha2Code": "AD",
        "alpha3Code": "AND",
        "flag": "https://flagcdn.com/ad.svg",
        "callingCodes": [
            "376"
        ]
    },
    {
        "name": "Angola",
        "alpha2Code": "AO",
        "alpha3Code": "AGO",
        "flag": "https://flagcdn.com/ao.svg",
        "callingCodes": [
            "244"
        ]
    },
    {
        "name": "Anguilla",
        "alpha2Code": "AI",
        "alpha3Code": "AIA",
        "flag": "https://flagcdn.com/ai.svg",
        "callingCodes": [
            "1"
        ]
    },
    {
        "name": "Antarctica",
        "alpha2Code": "AQ",
        "alpha3Code": "ATA",
        "flag": "https://flagcdn.com/aq.svg",
        "callingCodes": [
            "672"
        ]
    },
    {
        "name": "Antigua and Barbuda",
        "alpha2Code": "AG",
        "alpha3Code": "ATG",
        "flag": "https://flagcdn.com/ag.svg",
        "callingCodes": [
            "1"
        ]
    },
    {
        "name": "Argentina",
        "alpha2Code": "AR",
        "alpha3Code": "ARG",
        "flag": "https://flagcdn.com/ar.svg",
        "callingCodes": [
            "54"
        ]
    },
    {
        "name": "Armenia",
        "alpha2Code": "AM",
        "alpha3Code": "ARM",
        "flag": "https://flagcdn.com/am.svg",
        "callingCodes": [
            "374"
        ]
    },
    {
        "name": "Aruba",
        "alpha2Code": "AW",
        "alpha3Code": "ABW",
        "flag": "https://flagcdn.com/aw.svg",
        "callingCodes": [
            "297"
        ]
    },
    {
        "name": "Australia",
        "alpha2Code": "AU",
        "alpha3Code": "AUS",
        "flag": "https://flagcdn.com/au.svg",
        "callingCodes": [
            "61"
        ]
    },
    {
        "name": "Austria",
        "alpha2Code": "AT",
        "alpha3Code": "AUT",
        "flag": "https://flagcdn.com/at.svg",
        "callingCodes": [
            "43"
        ]
    },
    {
        "name": "Azerbaijan",
        "alpha2Code": "AZ",
        "alpha3Code": "AZE",
        "flag": "https://flagcdn.com/az.svg",
        "callingCodes": [
            "994"
        ]
    },
    {
        "name": "Bahamas",
        "alpha2Code": "BS",
        "alpha3Code": "BHS",
        "flag": "https://flagcdn.com/bs.svg",
        "callingCodes": [
            "1"
        ]
    },
    {
        "name": "Bahrain",
        "alpha2Code": "BH",
        "alpha3Code": "BHR",
        "flag": "https://flagcdn.com/bh.svg",
        "callingCodes": [
            "973"
        ]
    },
    {
        "name": "Bangladesh",
        "alpha2Code": "BD",
        "alpha3Code": "BGD",
        "flag": "https://flagcdn.com/bd.svg",
        "callingCodes": [
            "880"
        ]
    },
    {
        "name": "Barbados",
        "alpha2Code": "BB",
        "alpha3Code": "BRB",
        "flag": "https://flagcdn.com/bb.svg",
        "callingCodes": [
            "1"
        ]
    },
    {
        "name": "Belarus",
        "alpha2Code": "BY",
        "alpha3Code": "BLR",
        "flag": "https://flagcdn.com/by.svg",
        "callingCodes": [
            "375"
        ]
    },
    {
        "name": "Belgium",
        "alpha2Code": "BE",
        "alpha3Code": "BEL",
        "flag": "https://flagcdn.com/be.svg",
        "callingCodes": [
            "32"
        ]
    },
    {
        "name": "Belize",
        "alpha2Code": "BZ",
        "alpha3Code": "BLZ",
        "flag": "https://flagcdn.com/bz.svg",
        "callingCodes": [
            "501"
        ]
    },
    {
        "name": "Benin",
        "alpha2Code": "BJ",
        "alpha3Code": "BEN",
        "flag": "https://flagcdn.com/bj.svg",
        "callingCodes": [
            "229"
        ]
    },
    {
        "name": "Bermuda",
        "alpha2Code": "BM",
        "alpha3Code": "BMU",
        "flag": "https://flagcdn.com/bm.svg",
        "callingCodes": [
            "1"
        ]
    },
    {
        "name": "Bhutan",
        "alpha2Code": "BT",
        "alpha3Code": "BTN",
        "flag": "https://flagcdn.com/bt.svg",
        "callingCodes": [
            "975"
        ]
    },
    {
        "name": "Bolivia (Plurinational State of)",
        "alpha2Code": "BO",
        "alpha3Code": "BOL",
        "flag": "https://flagcdn.com/bo.svg",
        "callingCodes": [
            "591"
        ]
    },
    {
        "name": "Bonaire, Sint Eustatius and Saba",
        "alpha2Code": "BQ",
        "alpha3Code": "BES",
        "flag": "https://flagcdn.com/bq.svg",
        "callingCodes": [
            "599"
        ]
    },
    {
        "name": "Bosnia and Herzegovina",
        "alpha2Code": "BA",
        "alpha3Code": "BIH",
        "flag": "https://flagcdn.com/ba.svg",
        "callingCodes": [
            "387"
        ]
    },
    {
        "name": "Botswana",
        "alpha2Code": "BW",
        "alpha3Code": "BWA",
        "flag": "https://flagcdn.com/bw.svg",
        "callingCodes": [
            "267"
        ]
    },
    {
        "name": "Bouvet Island",
        "alpha2Code": "BV",
        "alpha3Code": "BVT",
        "flag": "https://flagcdn.com/bv.svg",
        "callingCodes": [
            "47"
        ]
    },
    {
        "name": "Brazil",
        "alpha2Code": "BR",
        "alpha3Code": "BRA",
        "flag": "https://flagcdn.com/br.svg",
        "callingCodes": [
            "55"
        ]
    },
    {
        "name": "British Indian Ocean Territory",
        "alpha2Code": "IO",
        "alpha3Code": "IOT",
        "flag": "https://flagcdn.com/io.svg",
        "callingCodes": [
            "246"
        ]
    },
    {
        "name": "United States Minor Outlying Islands",
        "alpha2Code": "UM",
        "alpha3Code": "UMI",
        "flag": "https://flagcdn.com/um.svg",
        "callingCodes": [
            "246"
        ]
    },
    {
        "name": "Virgin Islands (British)",
        "alpha2Code": "VG",
        "alpha3Code": "VGB",
        "flag": "https://flagcdn.com/vg.svg",
        "callingCodes": [
            "1"
        ]
    },
    {
        "name": "Virgin Islands (U.S.)",
        "alpha2Code": "VI",
        "alpha3Code": "VIR",
        "flag": "https://flagcdn.com/vi.svg",
        "callingCodes": [
            "1 340"
        ]
    },
    {
        "name": "Brunei Darussalam",
        "alpha2Code": "BN",
        "alpha3Code": "BRN",
        "flag": "https://flagcdn.com/bn.svg",
        "callingCodes": [
            "673"
        ]
    },
    {
        "name": "Bulgaria",
        "alpha2Code": "BG",
        "alpha3Code": "BGR",
        "flag": "https://flagcdn.com/bg.svg",
        "callingCodes": [
            "359"
        ]
    },
    {
        "name": "Burkina Faso",
        "alpha2Code": "BF",
        "alpha3Code": "BFA",
        "flag": "https://flagcdn.com/bf.svg",
        "callingCodes": [
            "226"
        ]
    },
    {
        "name": "Burundi",
        "alpha2Code": "BI",
        "alpha3Code": "BDI",
        "flag": "https://flagcdn.com/bi.svg",
        "callingCodes": [
            "257"
        ]
    },
    {
        "name": "Cambodia",
        "alpha2Code": "KH",
        "alpha3Code": "KHM",
        "flag": "https://flagcdn.com/kh.svg",
        "callingCodes": [
            "855"
        ]
    },
    {
        "name": "Cameroon",
        "alpha2Code": "CM",
        "alpha3Code": "CMR",
        "flag": "https://flagcdn.com/cm.svg",
        "callingCodes": [
            "237"
        ]
    },
    {
        "name": "Canada",
        "alpha2Code": "CA",
        "alpha3Code": "CAN",
        "flag": "https://flagcdn.com/ca.svg",
        "callingCodes": [
            "1"
        ]
    },
    {
        "name": "Cabo Verde",
        "alpha2Code": "CV",
        "alpha3Code": "CPV",
        "flag": "https://flagcdn.com/cv.svg",
        "callingCodes": [
            "238"
        ]
    },
    {
        "name": "Cayman Islands",
        "alpha2Code": "KY",
        "alpha3Code": "CYM",
        "flag": "https://flagcdn.com/ky.svg",
        "callingCodes": [
            "1"
        ]
    },
    {
        "name": "Central African Republic",
        "alpha2Code": "CF",
        "alpha3Code": "CAF",
        "flag": "https://flagcdn.com/cf.svg",
        "callingCodes": [
            "236"
        ]
    },
    {
        "name": "Chad",
        "alpha2Code": "TD",
        "alpha3Code": "TCD",
        "flag": "https://flagcdn.com/td.svg",
        "callingCodes": [
            "235"
        ]
    },
    {
        "name": "Chile",
        "alpha2Code": "CL",
        "alpha3Code": "CHL",
        "flag": "https://flagcdn.com/cl.svg",
        "callingCodes": [
            "56"
        ]
    },
    {
        "name": "China",
        "alpha2Code": "CN",
        "alpha3Code": "CHN",
        "flag": "https://flagcdn.com/cn.svg",
        "callingCodes": [
            "86"
        ]
    },
    {
        "name": "Christmas Island",
        "alpha2Code": "CX",
        "alpha3Code": "CXR",
        "flag": "https://flagcdn.com/cx.svg",
        "callingCodes": [
            "61"
        ]
    },
    {
        "name": "Cocos (Keeling) Islands",
        "alpha2Code": "CC",
        "alpha3Code": "CCK",
        "flag": "https://flagcdn.com/cc.svg",
        "callingCodes": [
            "61"
        ]
    },
    {
        "name": "Colombia",
        "alpha2Code": "CO",
        "alpha3Code": "COL",
        "flag": "https://flagcdn.com/co.svg",
        "callingCodes": [
            "57"
        ]
    },
    {
        "name": "Comoros",
        "alpha2Code": "KM",
        "alpha3Code": "COM",
        "flag": "https://flagcdn.com/km.svg",
        "callingCodes": [
            "269"
        ]
    },
    {
        "name": "Congo",
        "alpha2Code": "CG",
        "alpha3Code": "COG",
        "flag": "https://flagcdn.com/cg.svg",
        "callingCodes": [
            "242"
        ]
    },
    {
        "name": "Congo (Democratic Republic of the)",
        "alpha2Code": "CD",
        "alpha3Code": "COD",
        "flag": "https://flagcdn.com/cd.svg",
        "callingCodes": [
            "243"
        ]
    },
    {
        "name": "Cook Islands",
        "alpha2Code": "CK",
        "alpha3Code": "COK",
        "flag": "https://flagcdn.com/ck.svg",
        "callingCodes": [
            "682"
        ]
    },
    {
        "name": "Costa Rica",
        "alpha2Code": "CR",
        "alpha3Code": "CRI",
        "flag": "https://flagcdn.com/cr.svg",
        "callingCodes": [
            "506"
        ]
    },
    {
        "name": "Croatia",
        "alpha2Code": "HR",
        "alpha3Code": "HRV",
        "flag": "https://flagcdn.com/hr.svg",
        "callingCodes": [
            "385"
        ]
    },
    {
        "name": "Cuba",
        "alpha2Code": "CU",
        "alpha3Code": "CUB",
        "flag": "https://flagcdn.com/cu.svg",
        "callingCodes": [
            "53"
        ]
    },
    {
        "name": "Curaçao",
        "alpha2Code": "CW",
        "alpha3Code": "CUW",
        "flag": "https://flagcdn.com/cw.svg",
        "callingCodes": [
            "599"
        ]
    },
    {
        "name": "Cyprus",
        "alpha2Code": "CY",
        "alpha3Code": "CYP",
        "flag": "https://flagcdn.com/cy.svg",
        "callingCodes": [
            "357"
        ]
    },
    {
        "name": "Czech Republic",
        "alpha2Code": "CZ",
        "alpha3Code": "CZE",
        "flag": "https://flagcdn.com/cz.svg",
        "callingCodes": [
            "420"
        ]
    },
    {
        "name": "Denmark",
        "alpha2Code": "DK",
        "alpha3Code": "DNK",
        "flag": "https://flagcdn.com/dk.svg",
        "callingCodes": [
            "45"
        ]
    },
    {
        "name": "Djibouti",
        "alpha2Code": "DJ",
        "alpha3Code": "DJI",
        "flag": "https://flagcdn.com/dj.svg",
        "callingCodes": [
            "253"
        ]
    },
    {
        "name": "Dominica",
        "alpha2Code": "DM",
        "alpha3Code": "DMA",
        "flag": "https://flagcdn.com/dm.svg",
        "callingCodes": [
            "1"
        ]
    },
    {
        "name": "Dominican Republic",
        "alpha2Code": "DO",
        "alpha3Code": "DOM",
        "flag": "https://flagcdn.com/do.svg",
        "callingCodes": [
            "1"
        ]
    },
    {
        "name": "Ecuador",
        "alpha2Code": "EC",
        "alpha3Code": "ECU",
        "flag": "https://flagcdn.com/ec.svg",
        "callingCodes": [
            "593"
        ]
    },
    {
        "name": "Egypt",
        "alpha2Code": "EG",
        "alpha3Code": "EGY",
        "flag": "https://flagcdn.com/eg.svg",
        "callingCodes": [
            "20"
        ]
    },
    {
        "name": "El Salvador",
        "alpha2Code": "SV",
        "alpha3Code": "SLV",
        "flag": "https://flagcdn.com/sv.svg",
        "callingCodes": [
            "503"
        ]
    },
    {
        "name": "Equatorial Guinea",
        "alpha2Code": "GQ",
        "alpha3Code": "GNQ",
        "flag": "https://flagcdn.com/gq.svg",
        "callingCodes": [
            "240"
        ]
    },
    {
        "name": "Eritrea",
        "alpha2Code": "ER",
        "alpha3Code": "ERI",
        "flag": "https://flagcdn.com/er.svg",
        "callingCodes": [
            "291"
        ]
    },
    {
        "name": "Estonia",
        "alpha2Code": "EE",
        "alpha3Code": "EST",
        "flag": "https://flagcdn.com/ee.svg",
        "callingCodes": [
            "372"
        ]
    },
    {
        "name": "Ethiopia",
        "alpha2Code": "ET",
        "alpha3Code": "ETH",
        "flag": "https://flagcdn.com/et.svg",
        "callingCodes": [
            "251"
        ]
    },
    {
        "name": "Falkland Islands (Malvinas)",
        "alpha2Code": "FK",
        "alpha3Code": "FLK",
        "flag": "https://flagcdn.com/fk.svg",
        "callingCodes": [
            "500"
        ]
    },
    {
        "name": "Faroe Islands",
        "alpha2Code": "FO",
        "alpha3Code": "FRO",
        "flag": "https://flagcdn.com/fo.svg",
        "callingCodes": [
            "298"
        ]
    },
    {
        "name": "Fiji",
        "alpha2Code": "FJ",
        "alpha3Code": "FJI",
        "flag": "https://flagcdn.com/fj.svg",
        "callingCodes": [
            "679"
        ]
    },
    {
        "name": "Finland",
        "alpha2Code": "FI",
        "alpha3Code": "FIN",
        "flag": "https://flagcdn.com/fi.svg",
        "callingCodes": [
            "358"
        ]
    },
    {
        "name": "France",
        "alpha2Code": "FR",
        "alpha3Code": "FRA",
        "flag": "https://flagcdn.com/fr.svg",
        "callingCodes": [
            "33"
        ]
    },
    {
        "name": "French Guiana",
        "alpha2Code": "GF",
        "alpha3Code": "GUF",
        "flag": "https://flagcdn.com/gf.svg",
        "callingCodes": [
            "594"
        ]
    },
    {
        "name": "French Polynesia",
        "alpha2Code": "PF",
        "alpha3Code": "PYF",
        "flag": "https://flagcdn.com/pf.svg",
        "callingCodes": [
            "689"
        ]
    },
    {
        "name": "French Southern Territories",
        "alpha2Code": "TF",
        "alpha3Code": "ATF",
        "flag": "https://flagcdn.com/tf.svg",
        "callingCodes": [
            "262"
        ]
    },
    {
        "name": "Gabon",
        "alpha2Code": "GA",
        "alpha3Code": "GAB",
        "flag": "https://flagcdn.com/ga.svg",
        "callingCodes": [
            "241"
        ]
    },
    {
        "name": "Gambia",
        "alpha2Code": "GM",
        "alpha3Code": "GMB",
        "flag": "https://flagcdn.com/gm.svg",
        "callingCodes": [
            "220"
        ]
    },
    {
        "name": "Georgia",
        "alpha2Code": "GE",
        "alpha3Code": "GEO",
        "flag": "https://flagcdn.com/ge.svg",
        "callingCodes": [
            "995"
        ]
    },
    {
        "name": "Germany",
        "alpha2Code": "DE",
        "alpha3Code": "DEU",
        "flag": "https://flagcdn.com/de.svg",
        "callingCodes": [
            "49"
        ]
    },
    {
        "name": "Ghana",
        "alpha2Code": "GH",
        "alpha3Code": "GHA",
        "flag": "https://flagcdn.com/gh.svg",
        "callingCodes": [
            "233"
        ]
    },
    {
        "name": "Gibraltar",
        "alpha2Code": "GI",
        "alpha3Code": "GIB",
        "flag": "https://flagcdn.com/gi.svg",
        "callingCodes": [
            "350"
        ]
    },
    {
        "name": "Greece",
        "alpha2Code": "GR",
        "alpha3Code": "GRC",
        "flag": "https://flagcdn.com/gr.svg",
        "callingCodes": [
            "30"
        ]
    },
    {
        "name": "Greenland",
        "alpha2Code": "GL",
        "alpha3Code": "GRL",
        "flag": "https://flagcdn.com/gl.svg",
        "callingCodes": [
            "299"
        ]
    },
    {
        "name": "Grenada",
        "alpha2Code": "GD",
        "alpha3Code": "GRD",
        "flag": "https://flagcdn.com/gd.svg",
        "callingCodes": [
            "1"
        ]
    },
    {
        "name": "Guadeloupe",
        "alpha2Code": "GP",
        "alpha3Code": "GLP",
        "flag": "https://flagcdn.com/gp.svg",
        "callingCodes": [
            "590"
        ]
    },
    {
        "name": "Guam",
        "alpha2Code": "GU",
        "alpha3Code": "GUM",
        "flag": "https://flagcdn.com/gu.svg",
        "callingCodes": [
            "1"
        ]
    },
    {
        "name": "Guatemala",
        "alpha2Code": "GT",
        "alpha3Code": "GTM",
        "flag": "https://flagcdn.com/gt.svg",
        "callingCodes": [
            "502"
        ]
    },
    {
        "name": "Guernsey",
        "alpha2Code": "GG",
        "alpha3Code": "GGY",
        "flag": "https://flagcdn.com/gg.svg",
        "callingCodes": [
            "44"
        ]
    },
    {
        "name": "Guinea",
        "alpha2Code": "GN",
        "alpha3Code": "GIN",
        "flag": "https://flagcdn.com/gn.svg",
        "callingCodes": [
            "224"
        ]
    },
    {
        "name": "Guinea-Bissau",
        "alpha2Code": "GW",
        "alpha3Code": "GNB",
        "flag": "https://flagcdn.com/gw.svg",
        "callingCodes": [
            "245"
        ]
    },
    {
        "name": "Guyana",
        "alpha2Code": "GY",
        "alpha3Code": "GUY",
        "flag": "https://flagcdn.com/gy.svg",
        "callingCodes": [
            "592"
        ]
    },
    {
        "name": "Haiti",
        "alpha2Code": "HT",
        "alpha3Code": "HTI",
        "flag": "https://flagcdn.com/ht.svg",
        "callingCodes": [
            "509"
        ]
    },
    {
        "name": "Heard Island and McDonald Islands",
        "alpha2Code": "HM",
        "alpha3Code": "HMD",
        "flag": "https://flagcdn.com/hm.svg",
        "callingCodes": [
            "672"
        ]
    },
    {
        "name": "Vatican City",
        "alpha2Code": "VA",
        "alpha3Code": "VAT",
        "flag": "https://flagcdn.com/va.svg",
        "callingCodes": [
            "379"
        ]
    },
    {
        "name": "Honduras",
        "alpha2Code": "HN",
        "alpha3Code": "HND",
        "flag": "https://flagcdn.com/hn.svg",
        "callingCodes": [
            "504"
        ]
    },
    {
        "name": "Hungary",
        "alpha2Code": "HU",
        "alpha3Code": "HUN",
        "flag": "https://flagcdn.com/hu.svg",
        "callingCodes": [
            "36"
        ]
    },
    {
        "name": "Hong Kong",
        "alpha2Code": "HK",
        "alpha3Code": "HKG",
        "flag": "https://flagcdn.com/hk.svg",
        "callingCodes": [
            "852"
        ]
    },
    {
        "name": "Iceland",
        "alpha2Code": "IS",
        "alpha3Code": "ISL",
        "flag": "https://flagcdn.com/is.svg",
        "callingCodes": [
            "354"
        ]
    },
    {
        "name": "India",
        "alpha2Code": "IN",
        "alpha3Code": "IND",
        "flag": "https://flagcdn.com/in.svg",
        "callingCodes": [
            "91"
        ]
    },
    {
        "name": "Indonesia",
        "alpha2Code": "ID",
        "alpha3Code": "IDN",
        "flag": "https://flagcdn.com/id.svg",
        "callingCodes": [
            "62"
        ]
    },
    {
        "name": "Ivory Coast",
        "alpha2Code": "CI",
        "alpha3Code": "CIV",
        "flag": "https://flagcdn.com/ci.svg",
        "callingCodes": [
            "225"
        ]
    },
    {
        "name": "Iran (Islamic Republic of)",
        "alpha2Code": "IR",
        "alpha3Code": "IRN",
        "flag": "https://flagcdn.com/ir.svg",
        "callingCodes": [
            "98"
        ]
    },
    {
        "name": "Iraq",
        "alpha2Code": "IQ",
        "alpha3Code": "IRQ",
        "flag": "https://flagcdn.com/iq.svg",
        "callingCodes": [
            "964"
        ]
    },
    {
        "name": "Ireland",
        "alpha2Code": "IE",
        "alpha3Code": "IRL",
        "flag": "https://flagcdn.com/ie.svg",
        "callingCodes": [
            "353"
        ]
    },
    {
        "name": "Isle of Man",
        "alpha2Code": "IM",
        "alpha3Code": "IMN",
        "flag": "https://flagcdn.com/im.svg",
        "callingCodes": [
            "44"
        ]
    },
    {
        "name": "Israel",
        "alpha2Code": "IL",
        "alpha3Code": "ISR",
        "flag": "https://flagcdn.com/il.svg",
        "callingCodes": [
            "972"
        ]
    },
    {
        "name": "Italy",
        "alpha2Code": "IT",
        "alpha3Code": "ITA",
        "flag": "https://flagcdn.com/it.svg",
        "callingCodes": [
            "39"
        ]
    },
    {
        "name": "Jamaica",
        "alpha2Code": "JM",
        "alpha3Code": "JAM",
        "flag": "https://flagcdn.com/jm.svg",
        "callingCodes": [
            "1"
        ]
    },
    {
        "name": "Japan",
        "alpha2Code": "JP",
        "alpha3Code": "JPN",
        "flag": "https://flagcdn.com/jp.svg",
        "callingCodes": [
            "81"
        ]
    },
    {
        "name": "Jersey",
        "alpha2Code": "JE",
        "alpha3Code": "JEY",
        "flag": "https://flagcdn.com/je.svg",
        "callingCodes": [
            "44"
        ]
    },
    {
        "name": "Jordan",
        "alpha2Code": "JO",
        "alpha3Code": "JOR",
        "flag": "https://flagcdn.com/jo.svg",
        "callingCodes": [
            "962"
        ]
    },
    {
        "name": "Kazakhstan",
        "alpha2Code": "KZ",
        "alpha3Code": "KAZ",
        "flag": "https://flagcdn.com/kz.svg",
        "callingCodes": [
            "76",
            "77"
        ]
    },
    {
        "name": "Kenya",
        "alpha2Code": "KE",
        "alpha3Code": "KEN",
        "flag": "https://flagcdn.com/ke.svg",
        "callingCodes": [
            "254"
        ]
    },
    {
        "name": "Kiribati",
        "alpha2Code": "KI",
        "alpha3Code": "KIR",
        "flag": "https://flagcdn.com/ki.svg",
        "callingCodes": [
            "686"
        ]
    },
    {
        "name": "Kuwait",
        "alpha2Code": "KW",
        "alpha3Code": "KWT",
        "flag": "https://flagcdn.com/kw.svg",
        "callingCodes": [
            "965"
        ]
    },
    {
        "name": "Kyrgyzstan",
        "alpha2Code": "KG",
        "alpha3Code": "KGZ",
        "flag": "https://flagcdn.com/kg.svg",
        "callingCodes": [
            "996"
        ]
    },
    {
        "name": "Lao People's Democratic Republic",
        "alpha2Code": "LA",
        "alpha3Code": "LAO",
        "flag": "https://flagcdn.com/la.svg",
        "callingCodes": [
            "856"
        ]
    },
    {
        "name": "Latvia",
        "alpha2Code": "LV",
        "alpha3Code": "LVA",
        "flag": "https://flagcdn.com/lv.svg",
        "callingCodes": [
            "371"
        ]
    },
    {
        "name": "Lebanon",
        "alpha2Code": "LB",
        "alpha3Code": "LBN",
        "flag": "https://flagcdn.com/lb.svg",
        "callingCodes": [
            "961"
        ]
    },
    {
        "name": "Lesotho",
        "alpha2Code": "LS",
        "alpha3Code": "LSO",
        "flag": "https://flagcdn.com/ls.svg",
        "callingCodes": [
            "266"
        ]
    },
    {
        "name": "Liberia",
        "alpha2Code": "LR",
        "alpha3Code": "LBR",
        "flag": "https://flagcdn.com/lr.svg",
        "callingCodes": [
            "231"
        ]
    },
    {
        "name": "Libya",
        "alpha2Code": "LY",
        "alpha3Code": "LBY",
        "flag": "https://flagcdn.com/ly.svg",
        "callingCodes": [
            "218"
        ]
    },
    {
        "name": "Liechtenstein",
        "alpha2Code": "LI",
        "alpha3Code": "LIE",
        "flag": "https://flagcdn.com/li.svg",
        "callingCodes": [
            "423"
        ]
    },
    {
        "name": "Lithuania",
        "alpha2Code": "LT",
        "alpha3Code": "LTU",
        "flag": "https://flagcdn.com/lt.svg",
        "callingCodes": [
            "370"
        ]
    },
    {
        "name": "Luxembourg",
        "alpha2Code": "LU",
        "alpha3Code": "LUX",
        "flag": "https://flagcdn.com/lu.svg",
        "callingCodes": [
            "352"
        ]
    },
    {
        "name": "Macao",
        "alpha2Code": "MO",
        "alpha3Code": "MAC",
        "flag": "https://flagcdn.com/mo.svg",
        "callingCodes": [
            "853"
        ]
    },
    {
        "name": "North Macedonia",
        "alpha2Code": "MK",
        "alpha3Code": "MKD",
        "flag": "https://flagcdn.com/mk.svg",
        "callingCodes": [
            "389"
        ]
    },
    {
        "name": "Madagascar",
        "alpha2Code": "MG",
        "alpha3Code": "MDG",
        "flag": "https://flagcdn.com/mg.svg",
        "callingCodes": [
            "261"
        ]
    },
    {
        "name": "Malawi",
        "alpha2Code": "MW",
        "alpha3Code": "MWI",
        "flag": "https://flagcdn.com/mw.svg",
        "callingCodes": [
            "265"
        ]
    },
    {
        "name": "Malaysia",
        "alpha2Code": "MY",
        "alpha3Code": "MYS",
        "flag": "https://flagcdn.com/my.svg",
        "callingCodes": [
            "60"
        ]
    },
    {
        "name": "Maldives",
        "alpha2Code": "MV",
        "alpha3Code": "MDV",
        "flag": "https://flagcdn.com/mv.svg",
        "callingCodes": [
            "960"
        ]
    },
    {
        "name": "Mali",
        "alpha2Code": "ML",
        "alpha3Code": "MLI",
        "flag": "https://flagcdn.com/ml.svg",
        "callingCodes": [
            "223"
        ]
    },
    {
        "name": "Malta",
        "alpha2Code": "MT",
        "alpha3Code": "MLT",
        "flag": "https://flagcdn.com/mt.svg",
        "callingCodes": [
            "356"
        ]
    },
    {
        "name": "Marshall Islands",
        "alpha2Code": "MH",
        "alpha3Code": "MHL",
        "flag": "https://flagcdn.com/mh.svg",
        "callingCodes": [
            "692"
        ]
    },
    {
        "name": "Martinique",
        "alpha2Code": "MQ",
        "alpha3Code": "MTQ",
        "flag": "https://flagcdn.com/mq.svg",
        "callingCodes": [
            "596"
        ]
    },
    {
        "name": "Mauritania",
        "alpha2Code": "MR",
        "alpha3Code": "MRT",
        "flag": "https://flagcdn.com/mr.svg",
        "callingCodes": [
            "222"
        ]
    },
    {
        "name": "Mauritius",
        "alpha2Code": "MU",
        "alpha3Code": "MUS",
        "flag": "https://flagcdn.com/mu.svg",
        "callingCodes": [
            "230"
        ]
    },
    {
        "name": "Mayotte",
        "alpha2Code": "YT",
        "alpha3Code": "MYT",
        "flag": "https://flagcdn.com/yt.svg",
        "callingCodes": [
            "262"
        ]
    },
    {
        "name": "Mexico",
        "alpha2Code": "MX",
        "alpha3Code": "MEX",
        "flag": "https://flagcdn.com/mx.svg",
        "callingCodes": [
            "52"
        ]
    },
    {
        "name": "Micronesia (Federated States of)",
        "alpha2Code": "FM",
        "alpha3Code": "FSM",
        "flag": "https://flagcdn.com/fm.svg",
        "callingCodes": [
            "691"
        ]
    },
    {
        "name": "Moldova (Republic of)",
        "alpha2Code": "MD",
        "alpha3Code": "MDA",
        "flag": "https://flagcdn.com/md.svg",
        "callingCodes": [
            "373"
        ]
    },
    {
        "name": "Monaco",
        "alpha2Code": "MC",
        "alpha3Code": "MCO",
        "flag": "https://flagcdn.com/mc.svg",
        "callingCodes": [
            "377"
        ]
    },
    {
        "name": "Mongolia",
        "alpha2Code": "MN",
        "alpha3Code": "MNG",
        "flag": "https://flagcdn.com/mn.svg",
        "callingCodes": [
            "976"
        ]
    },
    {
        "name": "Montenegro",
        "alpha2Code": "ME",
        "alpha3Code": "MNE",
        "flag": "https://flagcdn.com/me.svg",
        "callingCodes": [
            "382"
        ]
    },
    {
        "name": "Montserrat",
        "alpha2Code": "MS",
        "alpha3Code": "MSR",
        "flag": "https://flagcdn.com/ms.svg",
        "callingCodes": [
            "1"
        ]
    },
    {
        "name": "Morocco",
        "alpha2Code": "MA",
        "alpha3Code": "MAR",
        "flag": "https://flagcdn.com/ma.svg",
        "callingCodes": [
            "212"
        ]
    },
    {
        "name": "Mozambique",
        "alpha2Code": "MZ",
        "alpha3Code": "MOZ",
        "flag": "https://flagcdn.com/mz.svg",
        "callingCodes": [
            "258"
        ]
    },
    {
        "name": "Myanmar",
        "alpha2Code": "MM",
        "alpha3Code": "MMR",
        "flag": "https://flagcdn.com/mm.svg",
        "callingCodes": [
            "95"
        ]
    },
    {
        "name": "Namibia",
        "alpha2Code": "NA",
        "alpha3Code": "NAM",
        "flag": "https://flagcdn.com/na.svg",
        "callingCodes": [
            "264"
        ]
    },
    {
        "name": "Nauru",
        "alpha2Code": "NR",
        "alpha3Code": "NRU",
        "flag": "https://flagcdn.com/nr.svg",
        "callingCodes": [
            "674"
        ]
    },
    {
        "name": "Nepal",
        "alpha2Code": "NP",
        "alpha3Code": "NPL",
        "flag": "https://flagcdn.com/np.svg",
        "callingCodes": [
            "977"
        ]
    },
    {
        "name": "Netherlands",
        "alpha2Code": "NL",
        "alpha3Code": "NLD",
        "flag": "https://flagcdn.com/nl.svg",
        "callingCodes": [
            "31"
        ]
    },
    {
        "name": "New Caledonia",
        "alpha2Code": "NC",
        "alpha3Code": "NCL",
        "flag": "https://flagcdn.com/nc.svg",
        "callingCodes": [
            "687"
        ]
    },
    {
        "name": "New Zealand",
        "alpha2Code": "NZ",
        "alpha3Code": "NZL",
        "flag": "https://flagcdn.com/nz.svg",
        "callingCodes": [
            "64"
        ]
    },
    {
        "name": "Nicaragua",
        "alpha2Code": "NI",
        "alpha3Code": "NIC",
        "flag": "https://flagcdn.com/ni.svg",
        "callingCodes": [
            "505"
        ]
    },
    {
        "name": "Niger",
        "alpha2Code": "NE",
        "alpha3Code": "NER",
        "flag": "https://flagcdn.com/ne.svg",
        "callingCodes": [
            "227"
        ]
    },
    {
        "name": "Nigeria",
        "alpha2Code": "NG",
        "alpha3Code": "NGA",
        "flag": "https://flagcdn.com/ng.svg",
        "callingCodes": [
            "234"
        ]
    },
    {
        "name": "Niue",
        "alpha2Code": "NU",
        "alpha3Code": "NIU",
        "flag": "https://flagcdn.com/nu.svg",
        "callingCodes": [
            "683"
        ]
    },
    {
        "name": "Norfolk Island",
        "alpha2Code": "NF",
        "alpha3Code": "NFK",
        "flag": "https://flagcdn.com/nf.svg",
        "callingCodes": [
            "672"
        ]
    },
    {
        "name": "Korea (Democratic People's Republic of)",
        "alpha2Code": "KP",
        "alpha3Code": "PRK",
        "flag": "https://flagcdn.com/kp.svg",
        "callingCodes": [
            "850"
        ]
    },
    {
        "name": "Northern Mariana Islands",
        "alpha2Code": "MP",
        "alpha3Code": "MNP",
        "flag": "https://flagcdn.com/mp.svg",
        "callingCodes": [
            "1"
        ]
    },
    {
        "name": "Norway",
        "alpha2Code": "NO",
        "alpha3Code": "NOR",
        "flag": "https://flagcdn.com/no.svg",
        "callingCodes": [
            "47"
        ]
    },
    {
        "name": "Oman",
        "alpha2Code": "OM",
        "alpha3Code": "OMN",
        "flag": "https://flagcdn.com/om.svg",
        "callingCodes": [
            "968"
        ]
    },
    {
        "name": "Pakistan",
        "alpha2Code": "PK",
        "alpha3Code": "PAK",
        "flag": "https://flagcdn.com/pk.svg",
        "callingCodes": [
            "92"
        ]
    },
    {
        "name": "Palau",
        "alpha2Code": "PW",
        "alpha3Code": "PLW",
        "flag": "https://flagcdn.com/pw.svg",
        "callingCodes": [
            "680"
        ]
    },
    {
        "name": "Palestine, State of",
        "alpha2Code": "PS",
        "alpha3Code": "PSE",
        "flag": "https://flagcdn.com/ps.svg",
        "callingCodes": [
            "970"
        ]
    },
    {
        "name": "Panama",
        "alpha2Code": "PA",
        "alpha3Code": "PAN",
        "flag": "https://flagcdn.com/pa.svg",
        "callingCodes": [
            "507"
        ]
    },
    {
        "name": "Papua New Guinea",
        "alpha2Code": "PG",
        "alpha3Code": "PNG",
        "flag": "https://flagcdn.com/pg.svg",
        "callingCodes": [
            "675"
        ]
    },
    {
        "name": "Paraguay",
        "alpha2Code": "PY",
        "alpha3Code": "PRY",
        "flag": "https://flagcdn.com/py.svg",
        "callingCodes": [
            "595"
        ]
    },
    {
        "name": "Peru",
        "alpha2Code": "PE",
        "alpha3Code": "PER",
        "flag": "https://flagcdn.com/pe.svg",
        "callingCodes": [
            "51"
        ]
    },
    {
        "name": "Philippines",
        "alpha2Code": "PH",
        "alpha3Code": "PHL",
        "flag": "https://flagcdn.com/ph.svg",
        "callingCodes": [
            "63"
        ]
    },
    {
        "name": "Pitcairn",
        "alpha2Code": "PN",
        "alpha3Code": "PCN",
        "flag": "https://flagcdn.com/pn.svg",
        "callingCodes": [
            "64"
        ]
    },
    {
        "name": "Poland",
        "alpha2Code": "PL",
        "alpha3Code": "POL",
        "flag": "https://flagcdn.com/pl.svg",
        "callingCodes": [
            "48"
        ]
    },
    {
        "name": "Portugal",
        "alpha2Code": "PT",
        "alpha3Code": "PRT",
        "flag": "https://flagcdn.com/pt.svg",
        "callingCodes": [
            "351"
        ]
    },
    {
        "name": "Puerto Rico",
        "alpha2Code": "PR",
        "alpha3Code": "PRI",
        "flag": "https://flagcdn.com/pr.svg",
        "callingCodes": [
            "1"
        ]
    },
    {
        "name": "Qatar",
        "alpha2Code": "QA",
        "alpha3Code": "QAT",
        "flag": "https://flagcdn.com/qa.svg",
        "callingCodes": [
            "974"
        ]
    },
    {
        "name": "Republic of Kosovo",
        "alpha2Code": "XK",
        "alpha3Code": "UNK",
        "flag": "https://flagcdn.com/xk.svg",
        "callingCodes": [
            "383"
        ]
    },
    {
        "name": "Réunion",
        "alpha2Code": "RE",
        "alpha3Code": "REU",
        "flag": "https://flagcdn.com/re.svg",
        "callingCodes": [
            "262"
        ]
    },
    {
        "name": "Romania",
        "alpha2Code": "RO",
        "alpha3Code": "ROU",
        "flag": "https://flagcdn.com/ro.svg",
        "callingCodes": [
            "40"
        ]
    },
    {
        "name": "Russian Federation",
        "alpha2Code": "RU",
        "alpha3Code": "RUS",
        "flag": "https://flagcdn.com/ru.svg",
        "callingCodes": [
            "7"
        ]
    },
    {
        "name": "Rwanda",
        "alpha2Code": "RW",
        "alpha3Code": "RWA",
        "flag": "https://flagcdn.com/rw.svg",
        "callingCodes": [
            "250"
        ]
    },
    {
        "name": "Saint Barthélemy",
        "alpha2Code": "BL",
        "alpha3Code": "BLM",
        "flag": "https://flagcdn.com/bl.svg",
        "callingCodes": [
            "590"
        ]
    },
    {
        "name": "Saint Helena, Ascension and Tristan da Cunha",
        "alpha2Code": "SH",
        "alpha3Code": "SHN",
        "flag": "https://flagcdn.com/sh.svg",
        "callingCodes": [
            "290"
        ]
    },
    {
        "name": "Saint Kitts and Nevis",
        "alpha2Code": "KN",
        "alpha3Code": "KNA",
        "flag": "https://flagcdn.com/kn.svg",
        "callingCodes": [
            "1"
        ]
    },
    {
        "name": "Saint Lucia",
        "alpha2Code": "LC",
        "alpha3Code": "LCA",
        "flag": "https://flagcdn.com/lc.svg",
        "callingCodes": [
            "1"
        ]
    },
    {
        "name": "Saint Martin (French part)",
        "alpha2Code": "MF",
        "alpha3Code": "MAF",
        "flag": "https://flagcdn.com/mf.svg",
        "callingCodes": [
            "590"
        ]
    },
    {
        "name": "Saint Pierre and Miquelon",
        "alpha2Code": "PM",
        "alpha3Code": "SPM",
        "flag": "https://flagcdn.com/pm.svg",
        "callingCodes": [
            "508"
        ]
    },
    {
        "name": "Saint Vincent and the Grenadines",
        "alpha2Code": "VC",
        "alpha3Code": "VCT",
        "flag": "https://flagcdn.com/vc.svg",
        "callingCodes": [
            "1"
        ]
    },
    {
        "name": "Samoa",
        "alpha2Code": "WS",
        "alpha3Code": "WSM",
        "flag": "https://flagcdn.com/ws.svg",
        "callingCodes": [
            "685"
        ]
    },
    {
        "name": "San Marino",
        "alpha2Code": "SM",
        "alpha3Code": "SMR",
        "flag": "https://flagcdn.com/sm.svg",
        "callingCodes": [
            "378"
        ]
    },
    {
        "name": "Sao Tome and Principe",
        "alpha2Code": "ST",
        "alpha3Code": "STP",
        "flag": "https://flagcdn.com/st.svg",
        "callingCodes": [
            "239"
        ]
    },
    {
        "name": "Saudi Arabia",
        "alpha2Code": "SA",
        "alpha3Code": "SAU",
        "flag": "https://flagcdn.com/sa.svg",
        "callingCodes": [
            "966"
        ]
    },
    {
        "name": "Senegal",
        "alpha2Code": "SN",
        "alpha3Code": "SEN",
        "flag": "https://flagcdn.com/sn.svg",
        "callingCodes": [
            "221"
        ]
    },
    {
        "name": "Serbia",
        "alpha2Code": "RS",
        "alpha3Code": "SRB",
        "flag": "https://flagcdn.com/rs.svg",
        "callingCodes": [
            "381"
        ]
    },
    {
        "name": "Seychelles",
        "alpha2Code": "SC",
        "alpha3Code": "SYC",
        "flag": "https://flagcdn.com/sc.svg",
        "callingCodes": [
            "248"
        ]
    },
    {
        "name": "Sierra Leone",
        "alpha2Code": "SL",
        "alpha3Code": "SLE",
        "flag": "https://flagcdn.com/sl.svg",
        "callingCodes": [
            "232"
        ]
    },
    {
        "name": "Singapore",
        "alpha2Code": "SG",
        "alpha3Code": "SGP",
        "flag": "https://flagcdn.com/sg.svg",
        "callingCodes": [
            "65"
        ]
    },
    {
        "name": "Sint Maarten (Dutch part)",
        "alpha2Code": "SX",
        "alpha3Code": "SXM",
        "flag": "https://flagcdn.com/sx.svg",
        "callingCodes": [
            "1"
        ]
    },
    {
        "name": "Slovakia",
        "alpha2Code": "SK",
        "alpha3Code": "SVK",
        "flag": "https://flagcdn.com/sk.svg",
        "callingCodes": [
            "421"
        ]
    },
    {
        "name": "Slovenia",
        "alpha2Code": "SI",
        "alpha3Code": "SVN",
        "flag": "https://flagcdn.com/si.svg",
        "callingCodes": [
            "386"
        ]
    },
    {
        "name": "Solomon Islands",
        "alpha2Code": "SB",
        "alpha3Code": "SLB",
        "flag": "https://flagcdn.com/sb.svg",
        "callingCodes": [
            "677"
        ]
    },
    {
        "name": "Somalia",
        "alpha2Code": "SO",
        "alpha3Code": "SOM",
        "flag": "https://flagcdn.com/so.svg",
        "callingCodes": [
            "252"
        ]
    },
    {
        "name": "South Africa",
        "alpha2Code": "ZA",
        "alpha3Code": "ZAF",
        "flag": "https://flagcdn.com/za.svg",
        "callingCodes": [
            "27"
        ]
    },
    {
        "name": "South Georgia and the South Sandwich Islands",
        "alpha2Code": "GS",
        "alpha3Code": "SGS",
        "flag": "https://flagcdn.com/gs.svg",
        "callingCodes": [
            "500"
        ]
    },
    {
        "name": "Korea (Republic of)",
        "alpha2Code": "KR",
        "alpha3Code": "KOR",
        "flag": "https://flagcdn.com/kr.svg",
        "callingCodes": [
            "82"
        ]
    },
    {
        "name": "Spain",
        "alpha2Code": "ES",
        "alpha3Code": "ESP",
        "flag": "https://flagcdn.com/es.svg",
        "callingCodes": [
            "34"
        ]
    },
    {
        "name": "Sri Lanka",
        "alpha2Code": "LK",
        "alpha3Code": "LKA",
        "flag": "https://flagcdn.com/lk.svg",
        "callingCodes": [
            "94"
        ]
    },
    {
        "name": "Sudan",
        "alpha2Code": "SD",
        "alpha3Code": "SDN",
        "flag": "https://flagcdn.com/sd.svg",
        "callingCodes": [
            "249"
        ]
    },
    {
        "name": "South Sudan",
        "alpha2Code": "SS",
        "alpha3Code": "SSD",
        "flag": "https://flagcdn.com/ss.svg",
        "callingCodes": [
            "211"
        ]
    },
    {
        "name": "Suriname",
        "alpha2Code": "SR",
        "alpha3Code": "SUR",
        "flag": "https://flagcdn.com/sr.svg",
        "callingCodes": [
            "597"
        ]
    },
    {
        "name": "Svalbard and Jan Mayen",
        "alpha2Code": "SJ",
        "alpha3Code": "SJM",
        "flag": "https://flagcdn.com/sj.svg",
        "callingCodes": [
            "47"
        ]
    },
    {
        "name": "Swaziland",
        "alpha2Code": "SZ",
        "alpha3Code": "SWZ",
        "flag": "https://flagcdn.com/sz.svg",
        "callingCodes": [
            "268"
        ]
    },
    {
        "name": "Sweden",
        "alpha2Code": "SE",
        "alpha3Code": "SWE",
        "flag": "https://flagcdn.com/se.svg",
        "callingCodes": [
            "46"
        ]
    },
    {
        "name": "Switzerland",
        "alpha2Code": "CH",
        "alpha3Code": "CHE",
        "flag": "https://flagcdn.com/ch.svg",
        "callingCodes": [
            "41"
        ]
    },
    {
        "name": "Syrian Arab Republic",
        "alpha2Code": "SY",
        "alpha3Code": "SYR",
        "flag": "https://flagcdn.com/sy.svg",
        "callingCodes": [
            "963"
        ]
    },
    {
        "name": "Taiwan",
        "alpha2Code": "TW",
        "alpha3Code": "TWN",
        "flag": "https://flagcdn.com/tw.svg",
        "callingCodes": [
            "886"
        ]
    },
    {
        "name": "Tajikistan",
        "alpha2Code": "TJ",
        "alpha3Code": "TJK",
        "flag": "https://flagcdn.com/tj.svg",
        "callingCodes": [
            "992"
        ]
    },
    {
        "name": "Tanzania, United Republic of",
        "alpha2Code": "TZ",
        "alpha3Code": "TZA",
        "flag": "https://flagcdn.com/tz.svg",
        "callingCodes": [
            "255"
        ]
    },
    {
        "name": "Thailand",
        "alpha2Code": "TH",
        "alpha3Code": "THA",
        "flag": "https://flagcdn.com/th.svg",
        "callingCodes": [
            "66"
        ]
    },
    {
        "name": "Timor-Leste",
        "alpha2Code": "TL",
        "alpha3Code": "TLS",
        "flag": "https://flagcdn.com/tl.svg",
        "callingCodes": [
            "670"
        ]
    },
    {
        "name": "Togo",
        "alpha2Code": "TG",
        "alpha3Code": "TGO",
        "flag": "https://flagcdn.com/tg.svg",
        "callingCodes": [
            "228"
        ]
    },
    {
        "name": "Tokelau",
        "alpha2Code": "TK",
        "alpha3Code": "TKL",
        "flag": "https://flagcdn.com/tk.svg",
        "callingCodes": [
            "690"
        ]
    },
    {
        "name": "Tonga",
        "alpha2Code": "TO",
        "alpha3Code": "TON",
        "flag": "https://flagcdn.com/to.svg",
        "callingCodes": [
            "676"
        ]
    },
    {
        "name": "Trinidad and Tobago",
        "alpha2Code": "TT",
        "alpha3Code": "TTO",
        "flag": "https://flagcdn.com/tt.svg",
        "callingCodes": [
            "1"
        ]
    },
    {
        "name": "Tunisia",
        "alpha2Code": "TN",
        "alpha3Code": "TUN",
        "flag": "https://flagcdn.com/tn.svg",
        "callingCodes": [
            "216"
        ]
    },
    {
        "name": "Turkey",
        "alpha2Code": "TR",
        "alpha3Code": "TUR",
        "flag": "https://flagcdn.com/tr.svg",
        "callingCodes": [
            "90"
        ]
    },
    {
        "name": "Turkmenistan",
        "alpha2Code": "TM",
        "alpha3Code": "TKM",
        "flag": "https://flagcdn.com/tm.svg",
        "callingCodes": [
            "993"
        ]
    },
    {
        "name": "Turks and Caicos Islands",
        "alpha2Code": "TC",
        "alpha3Code": "TCA",
        "flag": "https://flagcdn.com/tc.svg",
        "callingCodes": [
            "1"
        ]
    },
    {
        "name": "Tuvalu",
        "alpha2Code": "TV",
        "alpha3Code": "TUV",
        "flag": "https://flagcdn.com/tv.svg",
        "callingCodes": [
            "688"
        ]
    },
    {
        "name": "Uganda",
        "alpha2Code": "UG",
        "alpha3Code": "UGA",
        "flag": "https://flagcdn.com/ug.svg",
        "callingCodes": [
            "256"
        ]
    },
    {
        "name": "Ukraine",
        "alpha2Code": "UA",
        "alpha3Code": "UKR",
        "flag": "https://flagcdn.com/ua.svg",
        "callingCodes": [
            "380"
        ]
    },
    {
        "name": "United Arab Emirates",
        "alpha2Code": "AE",
        "alpha3Code": "ARE",
        "flag": "https://flagcdn.com/ae.svg",
        "callingCodes": [
            "971"
        ]
    },
    {
        "name": "United Kingdom of Great Britain and Northern Ireland",
        "alpha2Code": "GB",
        "alpha3Code": "GBR",
        "flag": "https://flagcdn.com/gb.svg",
        "callingCodes": [
            "44"
        ]
    },
    {
        "name": "United States of America",
        "alpha2Code": "US",
        "alpha3Code": "USA",
        "flag": "https://flagcdn.com/us.svg",
        "callingCodes": [
            "1"
        ]
    },
    {
        "name": "Uruguay",
        "alpha2Code": "UY",
        "alpha3Code": "URY",
        "flag": "https://flagcdn.com/uy.svg",
        "callingCodes": [
            "598"
        ]
    },
    {
        "name": "Uzbekistan",
        "alpha2Code": "UZ",
        "alpha3Code": "UZB",
        "flag": "https://flagcdn.com/uz.svg",
        "callingCodes": [
            "998"
        ]
    },
    {
        "name": "Vanuatu",
        "alpha2Code": "VU",
        "alpha3Code": "VUT",
        "flag": "https://flagcdn.com/vu.svg",
        "callingCodes": [
            "678"
        ]
    },
    {
        "name": "Venezuela (Bolivarian Republic of)",
        "alpha2Code": "VE",
        "alpha3Code": "VEN",
        "flag": "https://flagcdn.com/ve.svg",
        "callingCodes": [
            "58"
        ]
    },
    {
        "name": "Vietnam",
        "alpha2Code": "VN",
        "alpha3Code": "VNM",
        "flag": "https://flagcdn.com/vn.svg",
        "callingCodes": [
            "84"
        ]
    },
    {
        "name": "Wallis and Futuna",
        "alpha2Code": "WF",
        "alpha3Code": "WLF",
        "flag": "https://flagcdn.com/wf.svg",
        "callingCodes": [
            "681"
        ]
    },
    {
        "name": "Western Sahara",
        "alpha2Code": "EH",
        "alpha3Code": "ESH",
        "flag": "https://flagcdn.com/eh.svg",
        "callingCodes": [
            "212"
        ]
    },
    {
        "name": "Yemen",
        "alpha2Code": "YE",
        "alpha3Code": "YEM",
        "flag": "https://flagcdn.com/ye.svg",
        "callingCodes": [
            "967"
        ]
    },
    {
        "name": "Zambia",
        "alpha2Code": "ZM",
        "alpha3Code": "ZMB",
        "flag": "https://flagcdn.com/zm.svg",
        "callingCodes": [
            "260"
        ]
    },
    {
        "name": "Zimbabwe",
        "alpha2Code": "ZW",
        "alpha3Code": "ZWE",
        "flag": "https://flagcdn.com/zw.svg",
        "callingCodes": [
            "263"
        ]
    }
]


// response= {
//     "venueDetail": {
//         "_id": "6569a5f830e08600251ca593",
//         "location": {
//             "coordinates": [
//                 77.3867339,
//                 28.6145834
//             ]
//         },
//         "venue_name": "noida",
//         "opening_time": 1701369003116,
//         "closing_time": 1701412203116,
//         "venue_location": "114, Block C, Sector 67, Noida, Uttar Pradesh 201305, India",
//         "description": " one of the best   ground in this  area",
//         "cover_image": "https://groundbookings3bucket.s3.me-central-1.amazonaws.com/Uploads/Vendor/1701436748311/BB_2fbd0612-062a-4776-9c9f-3308de24f525_small.jpg",
//         "images_video": [
//             "https://groundbookings3bucket.s3.me-central-1.amazonaws.com/Uploads/Vendor/1701436754288/pexels_videos_3367+%281080p%29.mp4",
//             "https://groundbookings3bucket.s3.me-central-1.amazonaws.com/Uploads/Vendor/1701436766683/BB_2fbd0612-062a-4776-9c9f-3308de24f525_small.jpg",
//             "https://groundbookings3bucket.s3.me-central-1.amazonaws.com/Uploads/Vendor/1701436778191/BB_6a00a9c4-6c88-44ad-a991-b0a6a55b8b8b_small.jpg",
//             "https://groundbookings3bucket.s3.me-central-1.amazonaws.com/Uploads/Vendor/1701436814143/photo-1589802829985-817e51171b92.jpeg"
//         ],
//         "amenities": [
//             "ball"
//         ],
//         "is_favourite": false,
//         "effective_from_date": "",
//         "effective_from_time": "",
//         "effective_to_time": "",
//         "vendor_id": "6569a5d730e08600251ca588",
//         "city": {
//             "_id": "653960fad79dcb0020c31cf4",
//             "country": "AE",
//             "city": "Abu Dhabi Island and Internal Islands City",
//             "createdAt": "2023-10-25T18:39:54.569Z",
//             "updatedAt": "2023-10-25T18:39:54.569Z"
//         },
//         "createdAt": "2023-12-01T09:23:04.076Z",
//         "updatedAt": "2023-12-01T13:21:54.893Z",
//         "playGroundData": [
//             {
//                 "_id": "6569de12cd2dd300263be83f",
//                 "title": "cricket ",
//                 "time_slot": 150,
//                 "effective_time_slot": 0,
//                 "effective_from_date": "",
//                 "is_block": false,
//                 "ground_type": "65290682e66bab00202c060c",
//                 "vendor_id": "6569a5d730e08600251ca588",
//                 "venue_id": "6569a5f830e08600251ca593",
//                 "createdAt": "2023-12-01T13:22:26.140Z",
//                 "updatedAt": "2023-12-01T13:22:26.140Z",
//                 "ground_type_data": {
//                     "_id": "65290682e66bab00202c060c",
//                     "name": "Football Ground",
//                     "image": "https://groundbookings3bucket.s3.me-central-1.amazonaws.com/Uploads/Admin/1693032746490/wallpaper.jpg",
//                     "placement_no": 1,
//                     "is_block": true,
//                     "createdAt": "2023-10-13T08:57:38.890Z",
//                     "updatedAt": "2023-11-15T09:22:35.120Z"
//                 },
//                 "court": []
//             },
//             {
//                 "_id": "6569e07ccd2dd300263bec19",
//                 "title": "cort1",
//                 "time_slot": 150,
//                 "effective_time_slot": 0,
//                 "effective_from_date": "",
//                 "is_block": false,
//                 "ground_type": "652906cae66bab00202c063c",
//                 "vendor_id": "6569a5d730e08600251ca588",
//                 "venue_id": "6569a5f830e08600251ca593",
//                 "createdAt": "2023-12-01T13:32:44.254Z",
//                 "updatedAt": "2023-12-01T13:32:44.254Z",
//                 "ground_type_data": {
//                     "_id": "652906cae66bab00202c063c",
//                     "name": "Cricket",
//                     "image": "https://groundbookings3bucket.s3.me-central-1.amazonaws.com/Uploads/Admin/1693032746490/wallpaper.jpg",
//                     "placement_no": 3,
//                     "is_block": false,
//                     "createdAt": "2023-10-13T08:58:50.931Z",
//                     "updatedAt": "2023-10-20T13:38:47.623Z"
//                 },
//                 "court": [
//                     {
//                         "_id": "6569e094cd2dd300263bec26",
//                         "court_name_number": "a\\sTAND 1",
//                         "court_capacity": "11v11",
//                         "court_charges": 77787,
//                         "is_blocked": false,
//                         "playground_id": "6569e07ccd2dd300263bec19",
//                         "createdAt": "2023-12-01T13:33:08.751Z",
//                         "updatedAt": "2023-12-01T13:33:08.751Z"
//                     }
//                 ]
//             },
//             {
//                 "_id": "656db79570d0cf00252e91b0",
//                 "title": "ss",
//                 "time_slot": 90,
//                 "effective_time_slot": 0,
//                 "effective_from_date": "",
//                 "is_block": false,
//                 "ground_type": "652906cae66bab00202c063c",
//                 "vendor_id": "6569a5d730e08600251ca588",
//                 "venue_id": "6569a5f830e08600251ca593",
//                 "createdAt": "2023-12-04T11:27:17.818Z",
//                 "updatedAt": "2023-12-04T11:27:17.818Z",
//                 "ground_type_data": {
//                     "_id": "652906cae66bab00202c063c",
//                     "name": "Cricket",
//                     "image": "https://groundbookings3bucket.s3.me-central-1.amazonaws.com/Uploads/Admin/1693032746490/wallpaper.jpg",
//                     "placement_no": 3,
//                     "is_block": false,
//                     "createdAt": "2023-10-13T08:58:50.931Z",
//                     "updatedAt": "2023-10-20T13:38:47.623Z"
//                 },
//                 "court": [
//                     {
//                         "_id": "656db7a370d0cf00252e91f3",
//                         "court_name_number": "ss",
//                         "court_capacity": "1v1",
//                         "court_charges": 2222,
//                         "is_blocked": false,
//                         "playground_id": "656db79570d0cf00252e91b0",
//                         "createdAt": "2023-12-04T11:27:31.904Z",
//                         "updatedAt": "2023-12-04T11:27:31.904Z"
//                     }
//                 ]
//             }
//         ],
//         "Offers": [
//             {
//                 "_id": "656eeeeba76e77717a840436",
//                 "title": "Offer by Divya account",
//                 "discount_type": 0,
//                 "discount": 100,
//                 "max_discount": 60,
//                 "minimum_order_amt": 4500,
//                 "start_date": 1701714600000,
//                 "end_date": 1706639400000,
//                 "is_blocked": false,
//                 "vendor_id": "6569a5d730e08600251ca588",
//                 "sport_category": "65290682e66bab00202c060c",
//                 "createdAt": "2023-12-05T09:35:39.179Z",
//                 "updatedAt": "2023-12-05T09:35:49.492Z"
//             }
//         ]
//     },
//     "playgroundDetail": {
//         "title": "cort1",
//         "time_slot": 150,
//         "effective_time_slot": 0,
//         "effective_from_date": "",
//         "is_block": false,
//         "_id": "6569e07ccd2dd300263bec19",
//         "vendor_id": "6569a5d730e08600251ca588",
//         "venue_id": "6569a5f830e08600251ca593",
//         "createdAt": "2023-12-01T13:32:44.254Z",
//         "updatedAt": "2023-12-01T13:32:44.254Z",
//         "ground_type_data": {
//             "_id": "652906cae66bab00202c063c",
//             "name": "Cricket",
//             "image": "https://groundbookings3bucket.s3.me-central-1.amazonaws.com/Uploads/Admin/1693032746490/wallpaper.jpg",
//             "placement_no": 3,
//             "is_block": false
//         }
//     },
//     "courtList": [
//         {
//             "_id": "6569e094cd2dd300263bec26",
//             "court_name_number": "a\\sTAND 1",
//             "court_capacity": "11v11",
//             "court_charges": 77787,
//             "is_blocked": false,
//             "playground_id": "6569e07ccd2dd300263bec19",
//             "createdAt": "2023-12-01T13:33:08.751Z",
//             "updatedAt": "2023-12-01T13:33:08.751Z"
//         }
//     ],
//     "orderList": [
//         {
//             "_id": "656db74670d0cf00252e90ff",
//             "user_details": {
//                 "user_name": "test tteessty",
//                 "email": "fffffor@hotmail.com",
//                 "mobile_no": 93200340056593,
//                 "address": ""
//             },
//             "status": 0,
//             "user_id": "651f9d5cc4506800256d40c7",
//             "ground_details": {
//                 "opening_time": "1701714603000",
//                 "closing_time": "1701723603000",
//                 "booking_date": "1701714600000",
//                 "venue_id": "6569a5f830e08600251ca593",
//                 "playground_id": "6569e07ccd2dd300263bec19",
//                 "court_id": "6569e094cd2dd300263bec26"
//             },
//             "order_details": {
//                 "subtotal_price": 77787,
//                 "convenience_fee": 0,
//                 "promocode_price": 9,
//                 "promocode_id": "65606b734c3dae0025ff7985",
//                 "total": 77778
//             },
//             "payment_details": {
//                 "transaction_id": "030031727941",
//                 "payment_cart_id": "1859035473",
//                 "card_number": "4242",
//                 "payment_type": 0
//             },
//             "booking_id": "SPRT-8RY666S9",
//             "createdAt": "2023-12-04T11:25:58.849Z",
//             "updatedAt": "2023-12-04T11:25:58.849Z"
//         },
//         {
//             "_id": "656db81e70d0cf00252e92b3",
//             "user_details": {
//                 "user_name": "test tteessty",
//                 "email": "fffffor@hotmail.com",
//                 "mobile_no": 93200340056593,
//                 "address": ""
//             },
//             "status": 0,
//             "user_id": "651f9d5cc4506800256d40c7",
//             "ground_details": {
//                 "opening_time": "1701723603000",
//                 "closing_time": "1701732603000",
//                 "booking_date": "1701714600000",
//                 "venue_id": "6569a5f830e08600251ca593",
//                 "playground_id": "6569e07ccd2dd300263bec19",
//                 "court_id": "6569e094cd2dd300263bec26"
//             },
//             "order_details": {
//                 "subtotal_price": 77787,
//                 "convenience_fee": 0,
//                 "promocode_price": 50,
//                 "promocode_id": "65606cec4c3dae0025ff7a66",
//                 "total": 77737
//             },
//             "payment_details": {
//                 "transaction_id": "040031413914",
//                 "payment_cart_id": "4186425884",
//                 "card_number": "4242",
//                 "payment_type": 0
//             },
//             "booking_id": "SPRT-AHMNB33G",
//             "createdAt": "2023-12-04T11:29:34.065Z",
//             "updatedAt": "2023-12-04T11:29:34.065Z"
//         }
//     ]
// }

response= {
    "venueDetail": {
        "_id": "6569a5f830e08600251ca593",
        "location": {
            "coordinates": [
                77.3867339,
                28.6145834
            ]
        },
        "venue_name": "noida",
        "opening_time": 1701369003116,
        "closing_time": 1703766600000,
        "venue_location": "114, Block C, Sector 67, Noida, Uttar Pradesh 201305, India",
        "description": " one of the best   ground in this  area",
        "cover_image": "https://groundbookings3bucket.s3.me-central-1.amazonaws.com/Uploads/Vendor/1701436748311/BB_2fbd0612-062a-4776-9c9f-3308de24f525_small.jpg",
        "images_video": [
            "https://groundbookings3bucket.s3.me-central-1.amazonaws.com/Uploads/Vendor/1701436754288/pexels_videos_3367+%281080p%29.mp4",
            "https://groundbookings3bucket.s3.me-central-1.amazonaws.com/Uploads/Vendor/1701436766683/BB_2fbd0612-062a-4776-9c9f-3308de24f525_small.jpg",
            "https://groundbookings3bucket.s3.me-central-1.amazonaws.com/Uploads/Vendor/1701436778191/BB_6a00a9c4-6c88-44ad-a991-b0a6a55b8b8b_small.jpg",
            "https://groundbookings3bucket.s3.me-central-1.amazonaws.com/Uploads/Vendor/1701436814143/photo-1589802829985-817e51171b92.jpeg"
        ],
        "amenities": [
            "ball"
        ],
        "is_favourite": false,
        "effective_from_date": "",
        "effective_from_time": "",
        "effective_to_time": "",
        "vendor_id": "6569a5d730e08600251ca588",
        "city": {
            "_id": "653960fad79dcb0020c31cf4",
            "country": "AE",
            "city": "Abu Dhabi Island and Internal Islands City",
            "createdAt": "2023-10-25T18:39:54.569Z",
            "updatedAt": "2023-10-25T18:39:54.569Z"
        },
        "createdAt": "2023-12-01T09:23:04.076Z",
        "updatedAt": "2023-12-01T13:21:54.893Z",
        "playGroundData": [
            {
                "_id": "6569de12cd2dd300263be83f",
                "title": "cricket ",
                "time_slot": 150,
                "effective_time_slot": 0,
                "effective_from_date": "",
                "is_block": false,
                "ground_type": "65290682e66bab00202c060c",
                "vendor_id": "6569a5d730e08600251ca588",
                "venue_id": "6569a5f830e08600251ca593",
                "createdAt": "2023-12-01T13:22:26.140Z",
                "updatedAt": "2023-12-01T13:22:26.140Z",
                "ground_type_data": {
                    "_id": "65290682e66bab00202c060c",
                    "name": "Football Ground",
                    "image": "https://groundbookings3bucket.s3.me-central-1.amazonaws.com/Uploads/Admin/1693032746490/wallpaper.jpg",
                    "placement_no": 1,
                    "is_block": true,
                    "createdAt": "2023-10-13T08:57:38.890Z",
                    "updatedAt": "2023-11-15T09:22:35.120Z"
                },
                "court": [
                    {
                        "_id": "656db7a370d0cf00252e91f3",
                        "court_name_number": "ss",
                        "court_capacity": "1v1",
                        "court_charges": 2222,
                        "is_blocked": false,
                        "playground_id": "656db79570d0cf00252e91b0",
                        "createdAt": "2023-12-04T11:27:31.904Z",
                        "updatedAt": "2023-12-04T11:27:31.904Z"
                    }
                ]
            },
            {
                "_id": "6569e07ccd2dd300263bec19",
                "title": "cort1",
                "time_slot": 150,
                "effective_time_slot": 0,
                "effective_from_date": "",
                "is_block": false,
                "ground_type": "652906cae66bab00202c063c",
                "vendor_id": "6569a5d730e08600251ca588",
                "venue_id": "6569a5f830e08600251ca593",
                "createdAt": "2023-12-01T13:32:44.254Z",
                "updatedAt": "2023-12-01T13:32:44.254Z",
                "ground_type_data": {
                    "_id": "652906cae66bab00202c063c",
                    "name": "Cricket",
                    "image": "https://groundbookings3bucket.s3.me-central-1.amazonaws.com/Uploads/Admin/1693032746490/wallpaper.jpg",
                    "placement_no": 3,
                    "is_block": false,
                    "createdAt": "2023-10-13T08:58:50.931Z",
                    "updatedAt": "2023-10-20T13:38:47.623Z"
                },
                "court": [
                    {
                        "_id": "6569e094cd2dd300263bec26",
                        "court_name_number": "a\\sTAND 1",
                        "court_capacity": "11v11",
                        "court_charges": 77787,
                        "is_blocked": false,
                        "playground_id": "6569e07ccd2dd300263bec19",
                        "createdAt": "2023-12-01T13:33:08.751Z",
                        "updatedAt": "2023-12-01T13:33:08.751Z"
                    }
                ]
            },
            {
                "_id": "656db79570d0cf00252e91b0",
                "title": "ss",
                "time_slot": 90,
                "effective_time_slot": 0,
                "effective_from_date": "",
                "is_block": false,
                "ground_type": "652906cae66bab00202c063c",
                "vendor_id": "6569a5d730e08600251ca588",
                "venue_id": "6569a5f830e08600251ca593",
                "createdAt": "2023-12-04T11:27:17.818Z",
                "updatedAt": "2023-12-04T11:27:17.818Z",
                "ground_type_data": {
                    "_id": "652906cae66bab00202c063c",
                    "name": "Cricket",
                    "image": "https://groundbookings3bucket.s3.me-central-1.amazonaws.com/Uploads/Admin/1693032746490/wallpaper.jpg",
                    "placement_no": 3,
                    "is_block": false,
                    "createdAt": "2023-10-13T08:58:50.931Z",
                    "updatedAt": "2023-10-20T13:38:47.623Z"
                },
                "court": [
                    {
                        "_id": "656db7a370d0cf00252e91f3",
                        "court_name_number": "ss",
                        "court_capacity": "1v1",
                        "court_charges": 2222,
                        "is_blocked": false,
                        "playground_id": "656db79570d0cf00252e91b0",
                        "createdAt": "2023-12-04T11:27:31.904Z",
                        "updatedAt": "2023-12-04T11:27:31.904Z"
                    }
                ]
            },
            {
                "_id": "6569de12cd2dd300263be83f",
                "title": "cricket ",
                "time_slot": 150,
                "effective_time_slot": 0,
                "effective_from_date": "",
                "is_block": false,
                "ground_type": "65290682e66bab00202c060c",
                "vendor_id": "6569a5d730e08600251ca588",
                "venue_id": "6569a5f830e08600251ca593",
                "createdAt": "2023-12-01T13:22:26.140Z",
                "updatedAt": "2023-12-01T13:22:26.140Z",
                "ground_type_data": {
                    "_id": "65290682e66bab00202c060c",
                    "name": "Football Ground",
                    "image": "https://groundbookings3bucket.s3.me-central-1.amazonaws.com/Uploads/Admin/1693032746490/wallpaper.jpg",
                    "placement_no": 1,
                    "is_block": true,
                    "createdAt": "2023-10-13T08:57:38.890Z",
                    "updatedAt": "2023-11-15T09:22:35.120Z"
                },
                "court": [
                    {
                        "_id": "656db7a370d0cf00252e91f3",
                        "court_name_number": "ss",
                        "court_capacity": "1v1",
                        "court_charges": 2222,
                        "is_blocked": false,
                        "playground_id": "656db79570d0cf00252e91b0",
                        "createdAt": "2023-12-04T11:27:31.904Z",
                        "updatedAt": "2023-12-04T11:27:31.904Z"
                    }
                ]
            },
            {
                "_id": "6569e07ccd2dd300263bec19",
                "title": "cort1",
                "time_slot": 150,
                "effective_time_slot": 0,
                "effective_from_date": "",
                "is_block": false,
                "ground_type": "652906cae66bab00202c063c",
                "vendor_id": "6569a5d730e08600251ca588",
                "venue_id": "6569a5f830e08600251ca593",
                "createdAt": "2023-12-01T13:32:44.254Z",
                "updatedAt": "2023-12-01T13:32:44.254Z",
                "ground_type_data": {
                    "_id": "652906cae66bab00202c063c",
                    "name": "Cricket",
                    "image": "https://groundbookings3bucket.s3.me-central-1.amazonaws.com/Uploads/Admin/1693032746490/wallpaper.jpg",
                    "placement_no": 3,
                    "is_block": false,
                    "createdAt": "2023-10-13T08:58:50.931Z",
                    "updatedAt": "2023-10-20T13:38:47.623Z"
                },
                "court": [
                    {
                        "_id": "6569e094cd2dd300263bec26",
                        "court_name_number": "a\\sTAND 1",
                        "court_capacity": "11v11",
                        "court_charges": 77787,
                        "is_blocked": false,
                        "playground_id": "6569e07ccd2dd300263bec19",
                        "createdAt": "2023-12-01T13:33:08.751Z",
                        "updatedAt": "2023-12-01T13:33:08.751Z"
                    }
                ]
            },
            {
                "_id": "656db79570d0cf00252e91b0",
                "title": "ss",
                "time_slot": 90,
                "effective_time_slot": 0,
                "effective_from_date": "",
                "is_block": false,
                "ground_type": "652906cae66bab00202c063c",
                "vendor_id": "6569a5d730e08600251ca588",
                "venue_id": "6569a5f830e08600251ca593",
                "createdAt": "2023-12-04T11:27:17.818Z",
                "updatedAt": "2023-12-04T11:27:17.818Z",
                "ground_type_data": {
                    "_id": "652906cae66bab00202c063c",
                    "name": "Cricket",
                    "image": "https://groundbookings3bucket.s3.me-central-1.amazonaws.com/Uploads/Admin/1693032746490/wallpaper.jpg",
                    "placement_no": 3,
                    "is_block": false,
                    "createdAt": "2023-10-13T08:58:50.931Z",
                    "updatedAt": "2023-10-20T13:38:47.623Z"
                },
                "court": [
                    {
                        "_id": "656db7a370d0cf00252e91f3",
                        "court_name_number": "ss",
                        "court_capacity": "1v1",
                        "court_charges": 2222,
                        "is_blocked": false,
                        "playground_id": "656db79570d0cf00252e91b0",
                        "createdAt": "2023-12-04T11:27:31.904Z",
                        "updatedAt": "2023-12-04T11:27:31.904Z"
                    }
                ]
            }
        ],
        "Offers": [
            {
                "_id": "656eeeeba76e77717a840436",
                "title": "Offer by Divya account",
                "discount_type": 0,
                "discount": 100,
                "max_discount": 60,
                "minimum_order_amt": 4500,
                "start_date": 1701714600000,
                "end_date": 1706639400000,
                "is_blocked": false,
                "vendor_id": "6569a5d730e08600251ca588",
                "sport_category": "65290682e66bab00202c060c",
                "createdAt": "2023-12-05T09:35:39.179Z",
                "updatedAt": "2023-12-05T09:35:49.492Z"
            }
        ]
    },
    "playgroundDetail": {
        "title": "cort1",
        "time_slot": 150,
        "effective_time_slot": 0,
        "effective_from_date": "",
        "is_block": false,
        "_id": "6569e07ccd2dd300263bec19",
        "vendor_id": "6569a5d730e08600251ca588",
        "venue_id": "6569a5f830e08600251ca593",
        "createdAt": "2023-12-01T13:32:44.254Z",
        "updatedAt": "2023-12-01T13:32:44.254Z",
        "ground_type_data": {
            "_id": "652906cae66bab00202c063c",
            "name": "Cricket",
            "image": "https://groundbookings3bucket.s3.me-central-1.amazonaws.com/Uploads/Admin/1693032746490/wallpaper.jpg",
            "placement_no": 3,
            "is_block": false
        }
    },
    "courtList": [
        {
            "_id": "6569e094cd2dd300263bec26",
            "court_name_number": "a\\sTAND 1",
            "court_capacity": "11v11",
            "court_charges": 77787,
            "is_blocked": false,
            "playground_id": "6569e07ccd2dd300263bec19",
            "createdAt": "2023-12-01T13:33:08.751Z",
            "updatedAt": "2023-12-01T13:33:08.751Z"
        }
    ],
    "orderList": [
        {
            "_id": "656db74670d0cf00252e90ff",
            "user_details": {
                "user_name": "Punit jha",
                "email": "fffffor@hotmail.com",
                "mobile_no": 93200340056593,
                "address": ""
            },
            "status": 0,
            "user_id": "651f9d5cc4506800256d40c7",
            "ground_details": {
                "opening_time": "1701714603000",
                "closing_time": "1701723603000",
                "booking_date": "1701714600000",
                "venue_id": "6569a5f830e08600251ca593",
                "playground_id": "6569e07ccd2dd300263bec19",
                "court_id": "6569e094cd2dd300263bec26"
            },
            "order_details": {
                "subtotal_price": 77787,
                "convenience_fee": 0,
                "promocode_price": 9,
                "promocode_id": "65606b734c3dae0025ff7985",
                "total": 77778
            },
            "payment_details": {
                "transaction_id": "030031727941",
                "payment_cart_id": "1859035473",
                "card_number": "4242",
                "payment_type": 0
            },
            "booking_id": "SPRT-8RY666S9",
            "createdAt": "2023-12-04T11:25:58.849Z",
            "updatedAt": "2023-12-04T11:25:58.849Z"
        },
        {
            "_id": "656db81e70d0cf00252e92b3",
            "user_details": {
                "user_name": "testing",
                "email": "fffffor@hotmail.com",
                "mobile_no": 93200340056593,
                "address": ""
            },
            "status": 0,
            "user_id": "651f9d5cc4506800256d40c7",
            "ground_details": {
                "opening_time": "1701723603000",
                "closing_time": "1701732603000",
                "booking_date": "1701714600000",
                "venue_id": "6569a5f830e08600251ca593",
                "playground_id": "6569e07ccd2dd300263bec19",
                "court_id": "6569e094cd2dd300263bec26"
            },
            "order_details": {
                "subtotal_price": 77787,
                "convenience_fee": 0,
                "promocode_price": 50,
                "promocode_id": "65606cec4c3dae0025ff7a66",
                "total": 77737
            },
            "payment_details": {
                "transaction_id": "040031413914",
                "payment_cart_id": "4186425884",
                "card_number": "4242",
                "payment_type": 0
            },
            "booking_id": "SPRT-AHMNB33G",
            "createdAt": "2023-12-04T11:29:34.065Z",
            "updatedAt": "2023-12-04T11:29:34.065Z"
        }
    ]
}
  constructor(
    private Http:HttpClient,
  ) { }

  baseUrl = environment.baseUrl

  post(url:any,data:any){
   return this.Http.post(`${this.baseUrl}/api/auth/vendor/${url}`, data);
  }
  get(url:any){
   return this.Http.get(`${this.baseUrl}/api/auth/vendor/${url}`);
  }
  delete(url:any){
    return this.Http.delete(`${this.baseUrl}/api/auth/vendor/${url}`);
   }

   put(url:any,data:any){
    return this.Http.put(`${this.baseUrl}/api/auth/vendor/${url}`, data);
   }
   patch1(url:any,data:any){
    return this.Http.patch(`${this.baseUrl}/api/auth/vendor/${url}`, data);
   }
}
