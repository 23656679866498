import { Component } from '@angular/core';

@Component({
  selector: 'app-authentication-header',
  templateUrl: './authentication-header.component.html',
  styleUrls: ['./authentication-header.component.css']
})
export class AuthenticationHeaderComponent {
  selected = 'option2';


  language: any;


  changeLanguage(value: any) {
    this.language = value
    localStorage.setItem('language', this.language);
    window.location.reload()
  }

}
