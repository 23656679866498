
<div></div>


<div class="rightmodal  ground-canvas">
  <mat-dialog-content class="mat-typography">
    <div class="offcanvas1 offcanvas-end1 ground-canvas">
      <div class="offcanvas-header1">

        <h3 id="offcanvasRightLabel1"><i class="btn-close" class="bi bi-arrow-left me-2" mat-dialog-close></i>
          {{ showData?. ChangePassword}}</h3>
      </div>
      <form [formGroup]="changePasswordForm">
        <div class="offcanvas-body1">
          <div class="d-flex justify-content-start align-items-center">
          </div>
          <div class="login-input mt-3">
            <label for="email">{{showData?.CurrentPassword}}</label>
            <mat-form-field class="example-full-width">
              <input matInput [type]="hide ? 'password' : 'text'" formControlName="oldPass">
              <button mat-icon-button matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide">
                <div class="my-show p-2" matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hide">
                  <span style="cursor: pointer;"> {{hide ? showData?.show : showData?.hide}} </span>
                </div>
              </button>
            </mat-form-field>
            <mat-error *ngIf="submitted && f.oldPass.errors">
              <div *ngIf="submitted && f.oldPass.errors['required']" style="margin-top: -20px;
                    ">{{showData?.PleaseEnterOldpassword}}</div>
              <div *ngIf="f.oldPass.errors['pattern']" style="margin-top: -20px;
                  ">
                  {{showData?.passVal}}
              </div>
            </mat-error>
          </div>
          <div class="login-input mt-3">
            <label for="email">{{showData?.NewPassword}}</label>
            <mat-form-field class="example-full-width">
              <input matInput [type]="hide1 ? 'password' : 'text'" formControlName="newPass">
              <button mat-icon-button matSuffix (click)="hide1 = !hide1" [attr.aria-label]="'Hide password'"
                [attr.aria-pressed]="hide1">
                <div class="my-show " matSuffix (click)="hide1 = !hide1" [attr.aria-label]="'Hide password'"
                  [attr.aria-pressed]="hide1">
                  <span style="cursor: pointer;"> {{hide1 ? showData?.show : showData?.hide}} </span>
                </div>
              </button>
            </mat-form-field>
            <mat-error *ngIf="submitted && f.newPass.errors">
              <div *ngIf="submitted && f.newPass.errors['required']" style="margin-top: -20px;
                    ">{{showData?.PleaseEnterNewpassword}}</div>
              <div *ngIf="f.newPass.errors['pattern']" style="margin-top: -20px;
                    ">{{showData?.passVal}}
               </div>
            </mat-error>
          </div>
        </div>
      </form>
      <div class="mt-5">
        <button class="submit " (click)="changePassword() " data-bs-toggle="modal" data-bs-target="#changepsswrd">
        {{showData?.ChangePassword}}
        </button>
      </div>
    </div>
  </mat-dialog-content>
</div>