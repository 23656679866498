<section class="login d-flex align-items-center justify-content-center">
  <div class="login-box">
    <div class="row align-items-center">
      <div class="col-lg-6 login-img">
        <img class="sports-img" src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/login_logo_with_bg.png" alt="">
      </div>
      <div class="col-lg-6 login-text">
        <form [formGroup]="addCityForm" (keydown)="keyDownFunction($event)">
          <div class=" d-flex justify-content-between">
            <h4>Venue Details</h4>
            <h6 class="page-size">2/3</h6>

          </div>
          <div class="inner-content">

            <p class="font-gray welcome">Fill the sports ground location</p>


            <!-- <div
                        *ngIf="submitted && a['name'].errors"
                            class="text-danger">
                        <div *ngIf="a['name'].errors['required']">
                            Venue Address is required
                        </div>
                    </div> -->



            <div class="login-input1  loaction-icon mb-3 ">
              <label class="required" for="email">Venue Location</label>
              <!-- <img class="view-icon" src="assets/img/location.png"> -->
              <!-- <mat-form-field class="example-full-width"> -->
              <!-- <input class="form-control" (keydown.enter)="$event.preventDefault()" placeholder=" Enetr Your  location" formControlName = "venueLocation"> -->
              <!-- </mat-form-field> -->
              <input (keydown.enter)="$event.preventDefault()" matInput class="form-control location-padding" #search1
                type="text" placeholder="Enter Location" formControlName="venueLocation" onpaste="return false;" ondrop="return false;" >
              <div *ngIf="submitted && a['venueLocation'].errors" class="text-danger">
                <div *ngIf="a['venueLocation'].errors['required']">
                  Location is required
                </div>
              </div>

            </div>




            <!-- <agm-map [latitude]="28.6144" [longitude]="77.3868" [zoom]="6" [streetViewControl]="false" [zoomControl]="false" style="width: 280px ;height : 200px">
                            <agm-marker  [latitude]="28.6144" [longitude]="77.3868" (dragStart)="true" ></agm-marker>
                        </agm-map> -->

            <agm-map [latitude]="latitude1" [longitude]="longitude1" [zoom]="zoom" [streetViewControl]="false"
              [zoomControl]="false" style="width: 320px; height: 200px;">
              <agm-marker [latitude]="latitude1" [longitude]="longitude1" [markerDraggable]="true"
                (dragEnd)="markerDragEnd($event)"></agm-marker>
            </agm-map>





            <div class="login-input1  mt-3 mb-2">
              <label class="required" for="email">Venue Name</label>

              <input class="form-control" placeholder="Enter Venue Address" formControlName="venueName"onpaste="return false;" ondrop="return false;" >
                <div *ngIf="submitted && a['venueName'].errors" class="text-danger">
                  <div *ngIf="a['venueName'].errors['required']">
                    Venue Name is required
                  </div>
                </div>




              </div>
              <p class="font-gray welcome1">Opening & Closing Time</p>
              <div>
                <p> <span class="text-danger">Note:</span>If the opening and closing times are changed later, the new opening and closing time will take effect 15 days from the date of change.     </p>
              </div>
            <div class="login-input1 mb-2 mt-0 ">
              <label for="email">Time From <span class="req">*</span></label>
              <input [ngxTimepicker]="pickerFrom" class="form-control" placeholder="From"  [(ngModel)]="time1"
              [ngModelOptions]="{ standalone: true }" onkeydown="return false;"  oncut="return false" onCopy= "return false" onpaste="return false;" onselectstart="return false;" style=" caret-color: transparent;">
              <ngx-material-timepicker #pickerFrom></ngx-material-timepicker>
              <!-- <input type="time" class="form-control" placeholder="Time From" formControlName='openingTime'> -->
            </div>
            <div *ngIf="submitted && !time1" class="text-danger">

                Please select Opening Time

            </div>


            <div class="login-input1 ">
              <label class="required" for="email">Time To</label>
              <input [ngxTimepicker]="pickerTo" class="form-control" placeholder="To"  [(ngModel)]="time2"
              [ngModelOptions]="{ standalone: true }" onkeydown="return false;" oncut="return false"  onCopy= "return false" onpaste="return false;" onselectstart="return false;" style=" caret-color: transparent;">
              <ngx-material-timepicker #pickerTo></ngx-material-timepicker>
              <!-- <input type="time" class="form-control" placeholder="01:00" formControlName='closingTime'> -->
            </div>
            <div *ngIf="submitted && !time2" class="text-danger">

              Please select  closing Time

          </div>


            <div class="sub-log">
              <button class="login-btn" autofocus (click)="venueDetails()">Continue</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>

<!-- <script src="https://maps.googleapis.com/maps/api/js?key=AIzaSyDanyo5TQBadZiy9CQcD6q2YexStcqF7xw&libraries=places&callback=initMap"
    async defer></script> -->
