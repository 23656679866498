<app-authentication-header></app-authentication-header>

<section class="login d-flex align-items-center justify-content-center">
    <div class="login-box">
        <div class="row align-items-center">
            <div class="col-lg-6 login-img">
                <img class="sports-img" src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/login_logo_with_bg.png" alt="">
            </div>
            <div class="col-lg-6 login-text">
                <form [formGroup] = "signUpForm" (keydown)="keyDownFunction($event)">
                    <!-- <img class="w-50" src="assets/img/main-logo.png" alt=""> -->
                    <div class="fixed">

                        <div class="d-flex justify-content-between">
                          <h4>{{showData?.CreateAccount}}</h4>
                          <h6 class="page-size">1/3</h6>

                        </div>
                    </div>
                    <div class="inner-content">

                        <p class="font-blue welcome">{{showData?.BasicInfo}}</p>
                        <div class="login-input ">
                            <label  class="required" for="email">{{showData?.BusinessName}}</label>
                            <mat-form-field class="example-full-width">
                                <input matInput placeholder="{{showData?.EnterYourBussinessName}}" formControlName = "bussiness_name" (keypress)="onKeyPress($event)"   minlength="2" maxlength="40" onselectstart="return false" onpaste="return false;" onCopy="return false" onCut="return false" onDrag="return false" onDrop="return false" >
                            </mat-form-field>
                            <div *ngIf="submitted && a['bussiness_name'].errors" class="text-danger">
                                <div *ngIf="a['bussiness_name'].errors['required']">
                                    {{showData?.BusinessNameisrequired}}
                                </div>
                                <!-- <div *ngIf="a['bussiness_name'].errors['minlength']">
                                    Bussiness Name  minimum with 2 Charecter
                                </div> -->

                            </div>
                        </div>
                        <div class="login-input ">
                            <label  class="required" for="email">{{showData?.EmailID}}</label>
                            <mat-form-field class="example-full-width">
                                <input matInput placeholder="{{showData?.EnterEmailId}}" formControlName = "email" (keydown.space)="$event.preventDefault()" oncopy= "return false" onpaste= "return false">
                            </mat-form-field>

                            <mat-error *ngIf="submitted && a['email'].errors">
                                <div *ngIf="a['email'].errors['required'] ">{{showData?.PleaseEnterEmailID}}</div>
                                <div *ngIf="a['email'].errors['pattern'] ">{{showData?.PleaseEnteraValidEmailID}}
                                </div>
                            </mat-error>

                        </div>

                        <div class="login-input ">
                            <label  class="required" for="email">{{showData?.MobileNumber}}</label>
                            <input type="text" placeholder=" {{showData?.EnterMobileNumber}}"
                            ng2TelInput minlength="8" maxlength="15"
                            [ng2TelInputOptions]="{initialCountry: countryISOCodeFromLocal, separateDialCode:true}"
                            (hasError)="hasError($event)"
                            (ng2TelOutput)="getNumber($event)"
                            (intlTelInputObject)="telInputObject($event)"
                            (countryChange)="countryChange($event)"
                            (keyup)="getMobileNumber($event)" matInput class="phone-form w-100" onselectstart="return false" onpaste="return false;" onCopy="return false" onCut="return false" onDrag="return false" onDrop="return false"  formControlName="mobile_number" placeholder="Enter mobile number"
                                    onkeypress="return event.charCode > 47 && event.charCode < 58;"/>
                        </div>
                        <div *ngIf="submitted && a['mobile_number'].errors" class="text-danger">
                            <div *ngIf="a['mobile_number'].errors['required']">
                               {{showData?.MobileNumberisrequired}}
                            </div>
                            <!-- <div *ngIf="a['mobile_number'].errors['minlength']">
                                Please Enter  valid Mobile number
                            </div> -->

                        </div>


                        <div class="login-input">
                          <label  class="required" for="email">{{showData?.City}}</label>
                          <mat-form-field>

                              <!-- <mat-label>Select an option</mat-label> -->


                                <!-- <mat-select formControlName="city">
                                  <mat-option value="650854332ac1b300206cf009">Austrelia</mat-option>
                                  <mat-option value="2">japan</mat-option>
                                </mat-select> -->
                                <mat-select  formControlName="city" placeholder="{{showData?.SelectCity}}">
                                  <mat-option
                                    *ngFor="let city of getCityListData"
                                    [value]="city._id"
                                    >{{ city?.city }} </mat-option
                                  >

                                </mat-select>



                          </mat-form-field>

                          <div *ngIf="submitted && a['city'].errors" class="text-danger">
                            <div *ngIf="a['city'].errors['required']">
                                {{showData?.Cityisrequired}}
                            </div>
                        </div>
                      </div>

                        <p class="font-blue welcome1">{{showData?.SetPassword}}</p>
                        <div class="login-input">
                            <label  class="required" for="password">{{showData?.CreatePassword}}</label>
                            <mat-form-field appearance="fill">
                                <input matInput [type]="hide ? 'password' : 'text'" placeholder="{{showData?.EnterPassword}}" formControlName = "password" onpaste="return false;" ondrop="return false;" >
                                <div class="my-show point" matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                    <span> {{hide ? 'show' : 'hide'}} </span>
                                </div>
                            </mat-form-field>
                            <div class="text-danger" *ngIf="submitted && a.password.errors">
                                <div *ngIf="submitted && a.password.errors['required']">{{showData?.PleaseEnterPassword}}</div>
                                <div *ngIf="a.password.errors['pattern']">{{showData?.PasswordMust}} </div>
                            </div>

                        </div>

                        <div class="sign1 my-check">
                            <!-- <p><mat-checkbox class="example-margin" (click) = "agree = 1" value = '1'></mat-checkbox> I agree to <span class="font-green point">Term & Conditions </span> and  <span class="font-green point">Privacy Policy of the </span> App .</p> -->


                            <p><mat-checkbox class="example-margin" (change)="isChecked($event)"></mat-checkbox> {{showData?.Iagreeto}} <span class="font-green point">{{showData?.TermConditions}} </span> {{showData?.and}}  <span class="font-green point">{{showData?.PrivacyPolicyofthe}}</span> {{showData?.app}} .</p>

                            <div class="text-danger">
                                <div *ngIf="submitted && !termAndUse">{{showData?.PleaseacceptAllthetemscondition}} </div>
                            </div>
                        </div>

                        <div class="sub-log">
                            <button class="login-btn"  type="sumit" autofocus (click)="signUp()">{{showData?.SignUp}}</button>
                        </div>
                        <div class="sign text-center">
                            <p class="point" routerLink="/login"><span class="font-black">{{showData?.AlreadyaSportsbay}} </span> <span class="font-green"> {{showData?.Login}}</span></p>
                        </div>
                    </div>

                </form>
            </div>
        </div>
    </div>
</section>

