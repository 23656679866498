<div class="d-flex justify-content-between align-items-center auth-header">
    <div class="logo">
        <img src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/header_logo_white.png" alt="">
  
    </div>
    <div class="header-section">
        <img class="img-fluid" [matMenuTriggerFor]="menu" src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/hdr_language.png" alt="">
        <mat-menu class="lang-menu" #menu="matMenu">
          <button mat-menu-item>English</button>
          <button mat-menu-item>اَلْعَرَبِيَّةُ</button>
        </mat-menu>
        <img routerLink="/login" src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/hdr_logout.png" alt="">
  
    </div>
  </div>
  <section class="login d-flex align-items-center justify-content-center">
      <div class="login-box-admin">
       <div class="admion-aproval">
        <div class="admin-iner">
  
  <div class="d-flex images justify-content-center">
    <img src="/assets/img/Sportsbay_Vendor_Web_Panel_Assets/success_ic.png">
  
  </div>
  <div class="text-mid p-1">
  
    <p>Your password has been Changed Successfully !</p>
  </div>
  <div class="d-flex justify-content-center mb-3">
  
    <button class="btn point login-btn text-center w-50" routerLink="/login">Login Again </button>
  </div>
        </div>
  
       </div>
            </div>
            </section>
  