import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogComponent } from './dialog/dialog.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptorService } from './service/auth-interceptor.service';
import { ToastrModule } from 'ngx-toastr';
import { AuthenticationModule } from './authentication/authentication.module';
import { AgmCoreModule } from '@agm/core';
import { DatePipe } from '@angular/common';


@NgModule({
  declarations: [
    AppComponent,
    DialogComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    AuthenticationModule,
    DatePipe,
    ToastrModule.forRoot(), // ToastrModule added

    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyACfBzaJSVH8eur7U9JxdjI1bAeTLXsUJc',
      libraries: ['places','geometry']
    }),
    // ToastrModule.forRoot(), // ToastrModule added
  ],
  providers: [
    {
      multi:true,useClass:AuthInterceptorService,provide:HTTP_INTERCEPTORS,
    
    },
    DatePipe
  ],
  bootstrap: [AppComponent],
  // schemas:  [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AppModule { }
