<div class="d-flex justify-content-between align-items-center auth-header">
  <div class="logo">
      <img src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/header_logo_white.png" alt="">

  </div>
  <div class="header-section">
      <img class="img-fluid" [matMenuTriggerFor]="menu" src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/hdr_language.png" alt="">
      <mat-menu class="lang-menu" #menu="matMenu">
        <button mat-menu-item>English</button>
        <button mat-menu-item>اَلْعَرَبِيَّةُ</button>
      </mat-menu>
      <img routerLink="/login" src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/hdr_logout.png" alt="">

  </div>
</div>
<section class="login d-flex align-items-center justify-content-center">
    <div class="login-box4">
     <div class="admion-aproval">
      <div class="admin-iner">

<div class="d-flex images justify-content-center">
  <img src=".././../../assets/img/reject_regestration.png">

</div>
<div class="text-mid p-4" style="text-align: center;">

  <h4>Sorry To Say! Your Registration Request Has been Rejected</h4>
  <p>{{userData?.rejected_reason}}</p>
</div>
<div class="d-flex justify-content-center mb-3">

  <button class="btn  w-50  buttons-reject text-center" routerLink="/profile-creation">Update Profile</button>
  <!-- <button class="btn btn-success btn-bg text-center" routerLink="/login">Back To  Login</button> -->

</div>
      </div>

     </div>
          </div>
          </section>
