<section class="login d-flex align-items-center justify-content-center">
  <div class="login-box container">
    <div class="row align-items-center">
      <div class="col-lg-6">
        <img class="sports-img" src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/login_logo_with_bg.png" alt="" />
      </div>
      <div class="col-lg-6 login-text">
        <form [formGroup]="createProfileForm" (keydown)="keyDownFunction($event)">
          <div class=" d-flex justify-content-between">
            <h4>Profile Creation</h4>
            <h6 class="page-size">3/3</h6>

          </div>
          <div class="inner-content">
            <p class="text-size">Fill the Mandatory information to continue.</p>
            <label class=" mb-0">Upload Trade License</label>
            <p class="text-mid">At least one picture upload is mandatory</p>
            <div class="upload">
              <div class="row">
                <div class="col-lg-6" style="padding: 0px 0px;">
                  <div class="profile-pic-pnl profile-upload">
                    <div class="profile-pic4">
                      <img *ngIf="!tradeLicenseFrontImage" [src]="url1" />
                      <img *ngIf="tradeLicenseFrontImage"  [src]="tradeLicenseFrontImage" />


                    </div>
                    <div class="upload-ic">
                      <input type="file" (change)="uploadTradeLicenseFront($event)" class="fileUpload"
                        accept="image/*" />
                    </div>
                  </div>
                </div>

                <div class="col-lg-6" style="padding: 0px 0px;">
                  <div class="profile-pic-pnl profile-upload">
                    <div class="profile-pic4">
                      <img  *ngIf="!tradeLicenseBackImage"  [src]="url2" />
                      <img *ngIf="tradeLicenseBackImage"  [src]="tradeLicenseBackImage" />

                    </div>
                    <div class="upload-ic">
                      <input type="file" (change)="uploadTradeLicenseBack($event)" class="fileUpload"
                        accept="image/*" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="submitted && a['trade_license_front'].errors" class="text-danger">
              <div *ngIf="a['trade_license_front'].errors['required']">
                At least one picture is mandatory
              </div>
            </div>

            <label class="mb-0">Upload ID Proof</label>
            <p class="text-mid">At least one picture upload is mandatory</p>
            <div class="upload">
              <div class="row">
                <div class="col-lg-6" style="padding: 0px 0px;">
                  <div class="profile-pic-pnl profile-upload">
                    <div class="profile-pic4">
                      <img *ngIf="!idProodFrontImage"  [src]="url3" />
                      <img *ngIf="idProodFrontImage" [src]="idProodFrontImage" />

                    </div>
                    <div class="upload-ic">
                      <input type="file" (change)="uploadIdFront($event)" class="fileUpload" accept="image/*" />
                    </div>
                  </div>
                </div>
                <div class="col-lg-6" style="padding: 0px 0px;">
                  <div class="profile-pic-pnl profile-upload">
                    <div class="profile-pic4">
                      <img  *ngIf="!idProodBackImage"  [src]="url4" />
                      <img *ngIf="idProodBackImage" [src]="idProodBackImage" />

                    </div>
                    <div class="upload-ic">
                      <input type="file" (change)="uploadIdEnd($event)" class="fileUpload" accept="image/*" />
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div *ngIf="submitted && a['id_front'].errors" class="text-danger">
              <div *ngIf="a['id_front'].errors['required']">
                At least one picture is mandatory
              </div>
            </div>
            <p class="welcome font-blue">Bank Details</p>
            <div class="login-input">
              <label class="required" for="email">IBAN</label>
              <mat-form-field class="example-full-width">
                <!-- <input matInput placeholder="Enter IBAN" maxlength="30" formControlName="iban"  (keypress)="onlyAlphaNum($event)" id="ibanInput"/> -->
                <input matInput placeholder="Enter IBAN" maxlength="28" formControlName="iban"  style='text-transform:uppercase' (keypress)="onlyAlphaNumIBAN($event)" id="iban"  onselectstart="return false" onpaste="return false;" onCopy="return false" onCut="return false" onDrag="return false" onDrop="return false" />
              </mat-form-field>
              <div *ngIf="submitted && a['iban'].errors" class="text-danger">
                <div *ngIf="a['iban'].errors['required']">
                  IBAN is required
                </div>
              </div>
            </div>
            <div class="login-input">
              <label class="required" for="email">Bank Name</label>
              <mat-form-field class="example-full-width ">
                <input matInput placeholder="Enter Bank Name" maxlength="50"  ondrop="return false;"   formControlName="bank_name"   onselectstart="return false"  onCopy="return false" onCut="return false" onDrag="return false" onDrop="return false"/>
              </mat-form-field>

              <div *ngIf="submitted && a['bank_name'].errors" class="text-danger">
                <div *ngIf="a['bank_name'].errors['required']">
                  Bank name is required
                </div>
              </div>

            </div>
            <div class="login-input">
              <label class="required" for="email">Swift Code</label>
              <mat-form-field class="example-full-width">
                <input matInput placeholder="Enter Swift code"  (keydown.space)="$event.preventDefault()"(keypress)="onlyAlphaNumSwiptCode($event)" minlength="8"  maxlength="11"  style='text-transform:uppercase' formControlName="swift_code"   onselectstart="return false" onCopy="return false" onCut="return false" onDrag="return false" onDrop="return false" />
              </mat-form-field>

              <div *ngIf="submitted && a['swift_code'].errors" class="text-danger">
                <div *ngIf="a['swift_code'].errors['required']">
                  Swift Code is required
                </div>
                <div *ngIf="a['swift_code'].errors['minlength']">
                 Plesae Enter valid Swift Code
                </div>
              </div>


            </div>
            <div class="login-input">
              <label class="required" for="email">Account Holder Name</label>
              <mat-form-field class="example-full-width">
                <input matInput placeholder="Account Holder Name" maxlength="50"  (keypress)="onlyAlpha($event)"
                  formControlName="account_holder_name"  onpaste="return false;" ondrop="return false;" />
              </mat-form-field>
              <div *ngIf="submitted && a['account_holder_name'].errors" class="text-danger">
                <div *ngIf="a['account_holder_name'].errors['required']">
                  Account Holder Name is required
                </div>
              </div>
            </div>

            <div class="login-input">
              <label class="required" for="email">Acount Holder Full-Address</label>
              <!-- <mat-form-field class="example-full-width" style="height: 70px; border-radius: 15px !important;">
                <input matInput placeholder="Enter Acount Holder Full-Address"  (keypress)="addTrn($event)"
                  formControlName="account_holder_address"  id="address"  onselectstart="return false"   onpaste="return false;" onCopy="return false" onCut="return false" onDrag="return false" onDrop="return false" />
              </mat-form-field> -->
              <textarea name="" id="" cols="30" rows="4" class="form-control text-area" placeholder="Enter Account Holder Full Address"  (keypress)="addTrn($event)" formControlName="account_holder_address"  id="address"  onselectstart="return false"   onpaste="return false;" onCopy="return false" onCut="return false" onDrag="return false" onDrop="return false"></textarea>

              <div *ngIf="submitted && a['account_holder_address'].errors" class="text-danger">
                <div *ngIf="a['account_holder_address'].errors['required']">
                  Acount Holder Full-Address is required
                </div>
              </div>
            </div>

            <div class="login-input">
              <label for="email">Tax Registration Number TRN (if applicable)</label>
              <mat-form-field class="example-full-width">
                <input matInput placeholder=" Enter Tax Registration No." (keypress)="onlyAlphaForTrn($event)" maxlength="15"
                  formControlName="trn_no"  onpaste="return false;" ondrop="return false;" />
              </mat-form-field>
            </div>

            <div class="sub-log">
              <button class="point" class="login-btn" autofocus (click)="completeProfileMethod()">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>
