import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/service/api.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { SessionstorageService } from 'src/app/service/sessionstorage.service';
import { GetSetValueService } from 'src/app/service/get-set-value.service';

@Component({
  selector: 'app-otp',
  templateUrl: './otp.component.html',
  styleUrls: ['./otp.component.css'],
})
export class OtpComponent {
  ngOtpInputRef: any;
  hide = true;
  submitted: Boolean = false;
  otpRes: any;
  getSessionData: any;

  constructor(
    private api: ApiService,
    private toastr: ToastrService,
    private router: Router,
    private sessionStorage: SessionstorageService,
    private localLan: GetSetValueService,
  ) {}
  otpForm = new FormGroup({
    otp: new FormControl('', Validators.required),
  });
  get f() {
    return this.otpForm.controls;
  }
  ngOnInit() {
   
    this.getSessionData = this.sessionStorage.getLocalData('vendor-service');
    console.log(this.getSessionData);
    this.timer();
   
  }
  otp() {
    this.submitted = true;
    if (this.otpForm.invalid) {
      return;
    }
    // let data = this.otpForm.value
    let data = {
      otp: this.otpForm.value.otp,
      type: 1,
    };

    this.api.post('verify_otp', data).subscribe({
      next: (value: any) => {
        this.otpRes = value.response;
        this.toastr.success('OTP is verified.');
        this.sessionStorage.setLocalData('vendor-service', this.otpRes);
        this.router.navigateByUrl('/venue-details');
      },
      error: (err: any) => {
        console.log(err.error.message);
        this.toastr.error(err.error.message);
      },
    });
  }

  // ------------------timer----------------
  resendOtp() {
    this.api.get('resend_otp').subscribe({
      next: (res: any) => {
        this.timer();
        this.otpForm.reset();
        this.toastr.success(res.message);
      },
      error: (err: any) => {
        this.toastr.error(err.error.message);
      },
    });
  }

  counter: number = 0;
  secondValue: boolean = false;
  timer() {
    this.counter = 59;
    let startTimer = setInterval(() => {
      this.counter = this.counter - 1;
      if (this.counter == 1) {
        this.secondValue = true;
      }
      if (this.counter == 0) {
        this.secondValue = false;

        window.clearInterval(startTimer);
      }
    }, 1000);
  }
  getCounterData(counter: number) {
    let returnCounter = `${counter}`;
    if (returnCounter.length == 1) {
      return `0${this.counter}`;
    }
    return this.counter;
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      // rest of your code
    }
  }


}
