import { Component } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ChangePasswordComponent } from 'src/app/layout/more/change-password/change-password.component';
import { GetSetValueService } from 'src/app/service/get-set-value.service';

@Component({
  selector: 'app-aside',
  templateUrl: './aside.component.html',
  styleUrls: ['./aside.component.css'],
})
export class AsideComponent {
  userData: any;
  role: any;
  subModuleAccess: any;
  // dialog: any;

  constructor(
    private dialog: MatDialog,
    private localLan: GetSetValueService
  ) {}
  changepassword() {
    this.dialog.open(ChangePasswordComponent);
  }
  ngOnInit() {
    this.language = this.localLan.getLocalLanguage();
    this.setLanguageData();

    this.userData = sessionStorage.getItem('vendor-service');
    this.userData = JSON.parse(this.userData);
    // this.subModuleAccess = this.userData['moduleAccess']

    if (this.userData) {
      this.role = this.userData.isSubVendor;
    } else {
      console.log('subAdmin Error');
    }
  }

  language: any;
  showData: any;

  languageEng = {
    Dashboard: 'Dashboard',
    BookingManagement: 'Booking Management',
    Venuemanagement: 'Venue management',
    SportsGroundManagement: 'Sports Ground Management',
    offerManagement: 'offer Management',
    Ratingmanagement: 'Rating management',
    UserSubAdminMnagement: 'User Sub-Admin Management',
    PaymentManagement:'Payment Management',
    More:'More',
    ChangePassword:'ChangePassword',
    AboutSportsbay:'About Sportsbay',
    TermsConditions:'Terms & Conditions',
    PrivacyPolicy:'Privacy Policy',
    Help:'Help',
    FAQ:'FAQ',
    ContactUs:'Contact Us'
  };

  languageArabic = {
    Dashboard: 'لوحة التحكم',
    BookingManagement: 'إدارة الحجز',
    Venuemanagement: 'إدارة الملعب',
    SportsGroundManagement: 'إدارة الأرضية الرياضية',
    offerManagement: 'إدارة العروض',
    Ratingmanagement: 'إدارة التقييمات',
    UserSubAdminMnagement: 'إدارة المسؤول الفرعي',
    PaymentManagement:'إدارة الدفع',
    More:'المزيد',
    ChangePassword:'تغيير كلمة المرور',
    AboutSportsbay:'حول سبورتسباي',
    TermsConditions:'البنود و الظروف',
    PrivacyPolicy:'سياسة الخصوصية',
    Help:'يساعد',
    FAQ:'التعليمات',
    ContactUs:'اتصل بنا' 
   };

  setLanguageData() {
    if (this.language === 'english') {
      this.showData = this.languageEng;
    } else {
      this.showData = this.languageArabic;



      var elements1 = document.querySelectorAll('.aside');
      elements1.forEach((element1) => {
        element1.classList.add('rtl-direction');
        element1.classList.add('padding-0');

      });

      var elements2 = document.querySelectorAll('.main-content');
      elements2.forEach((element2) => {
        element2.classList.add('rtl-direction');
      });
    }
  }
}
