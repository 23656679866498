import { Component, OnInit, ElementRef, ViewChild, NgZone } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
// import { ApiService } from 'src/app/services/api.service';
import { ApiService } from 'src/app/service/api.service';
import { ToastrService } from 'ngx-toastr';
import { DateTime } from 'luxon';
import { MapsAPILoader } from '@agm/core';
import { Router } from '@angular/router';
import { SharedServiveService } from 'src/app/service/shared.service';
declare var google: any;

interface CalendarForMessagesProps {
  initialDate: DateTime;
}
@Component({
  selector: 'app-venue-details',
  templateUrl: './venue-details.component.html',
  styleUrls: ['./venue-details.component.css']
})
export class VenueDetailsComponent {
  // shared: any;
  constructor(
    private api: ApiService,
    private toastr: ToastrService,
    private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,
    private router: Router,
    private shared: SharedServiveService,

  ) { }
  userData: any
  submitted: any;
  vendor: any
  time1: any
  time2: any
 data: any
  startTime: any
  EndTime: any
 latitude1!: number;
  longitude1!: number;
  zoom!: number;
  address: any;
selectedMessage: any;
  private geoCoder: any;

  @ViewChild('search1')
  public search1ElementRef!: ElementRef;
  postal: any
  ngOnInit(): void {
    this.userData = JSON.parse(sessionStorage.getItem('vendor-service'));
   this.mapsAPILoader.load().then(() => {
      this.setCurrentLocation()
      this.geoCoder = new google.maps.Geocoder;

      let autocomplete1 = new google.maps.places.Autocomplete(this.search1ElementRef.nativeElement);
   autocomplete1.addListener("place_changed", () => {
        this.ngZone.run(() => {
     let place = autocomplete1.getPlace();
          console.log(this.addCityForm.value, "Venue locations")

          let reg = this.addCityForm.value.venueLocation
          for (let k = 0; k < place.length; k++) {
            const element = place[k];
            if (element.formatted_address.includes(reg)) {
              this.latitude1 = element.geometry.location.lat();
              this.longitude1 = element.geometry.location.lng();
              console.log('here is latitude :::::::::::::::', this.latitude1,
                'here is longitude:::::::::::: :', this.longitude1);
              this.addCityForm.patchValue({
                venueLocation: element.formatted_address,
                latitude: this.latitude1,
                longitude: this.longitude1
              })
            }

          }

          for (var i = 0; i < place.address_components.length; i++) {
            for (var j = 0; j < place.address_components[i].types.length; j++) {
              if (place.address_components[i].types[j] == "postal_code") {
                this.postal = place.address_components[i].long_name;
               

              }
            }
          }

          this.latitude1 = place.geometry.location.lat();
          this.longitude1 = place.geometry.location.lng();
          console.log(this.longitude1, "long", this.latitude1, "lat")

          this.getAddress(this.latitude1, this.longitude1).then((res: any) => {
           }).catch((err: any) => {
            alert("Please Enable location");
          });
          this.zoom = 12;
        });
      });
    });


    this.shared.currentMessage.subscribe(message => {
      console.log(message, "AAAAAA")
      if (message._id) {

        this.selectedMessage = message

      }
      if (this.selectedMessage) {
        console.log(this.selectedMessage, "***************************")
        this.addCityForm.patchValue({
          venueName: this.selectedMessage.name,
          // serviceCatogory:this.selectedMessage.serviceCatogory
          // serviceCatogory:this.selectedMessage.serviceCatogory
          //  code: this.selectedMessage.code,
          venueLocation: this.selectedMessage.venueLocation,
          //  latitude:this.selectedMessage.latitude,
          longitude: this.selectedMessage.longitude
        })


      }


    })


  }

  private setCurrentLocation() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        this.latitude1 = position.coords.latitude;
        this.longitude1 = position.coords.longitude;
        this.zoom = 8;
        this.getAddress(this.latitude1, this.longitude1);
      });
    }
  }


  markerDragEnd($event: any) {
    this.latitude1 = $event.coords.lat;
    this.longitude1 = $event.coords.lng;

    this.getAddress(this.latitude1, this.longitude1).then((res: any) => {
      // console.log(res, "lllllllllllllllllllllll")
      this.addCityForm.patchValue({
        venueLocation: res,
        latitude: this.latitude1,
        longitude: this.longitude1
      })
    }).catch((err: any) => {
      alert("Please Enable location");
    });
  }

  getAddress(latitude: any, longitude: any) {
    return new Promise((resolve, reject) => {

      this.geoCoder.geocode({ 'location': { lat: latitude, lng: longitude } }, (results: any, status: any) => {
        if (status === 'OK') {
          if (results[0]) {
            this.zoom = 6;
            // console.log(results, "result");

            for (var i = 0; i < results[0].address_components.length; i++) {
              for (var j = 0; j < results[0].address_components[i].types.length; j++) {
                if (results[0].address_components[i].types[j] == "postal_code") {
                  this.postal = results[0].address_components[i].long_name;
                  // console.log(this.postal, "my postal");
                  // this.addCityForm.patchValue({
                  //   code: this.postal

                  // })

                }
              }
            }

            this.address = results[0].formatted_address;
            resolve(this.address);
            console.log(this.address, "******** for****")

            //  this.address= this.address.split(','); 
            //  this.address = this.address[this.address.length - 3].trim(); 
            //  this.venueLocation =this.address
            sessionStorage.setItem('saveaddress', this.address);
            sessionStorage.setItem('address', this.address);
            // this.addCityForm.value.venueLocation =
            this.addCityForm.patchValue({
              venueLocation: this.address

            })

          } else {
            // window.alert('No results found');
            reject('Geocoder failed due to: ' + status);
          }
        } else {
          // window.alert('Geocoder failed due to: ' + status);
          reject('Geocoder failed due to: ' + status);
        }
      });

    })
  }

  vendorId: any
  addCityForm = new FormGroup({
    vendorId: new FormControl('', Validators.required),
    venueName: new FormControl('', Validators.required), //address
    venueLocation: new FormControl('', Validators.required), //location
    latitude: new FormControl(),
    longitude: new FormControl(),
  })

    venueDetails() {
    
    this.submitted = true
    let time1String = this.time1.slice(-2);
    let time2String = this.time2.slice(-2);
    let timeNumber1 = parseInt(this.time1.slice(0, 2))
    let timeNumber2 = parseInt(this.time2.slice(0, 2))
    let minutData1 = parseInt(this.time1.slice(3))
    let minutData2 = parseInt(this.time2.slice(3))
    let datas1 = parseInt(this.time1.slice(2))
    let datas2 = parseInt(this.time2.slice(2))

    console.log(time1String,"ll")
    console.log(timeNumber1,"jjjj")
if (time1String === 'PM' && time2String === 'AM') {
      this.toastr.warning('PM time in start time and AM time in closing time not  pemitted ')
      return

    }
    if (time1String === 'PM' && time2String === 'PM') {
      if (this.time1 == this.time2) {
        this.toastr.warning('Start  Time & End Time shoulde not Match Choose another  Time')
        return

      }
      else if (timeNumber1 > timeNumber2) {
        this.toastr.error("please select valid time")
        return

      }
      else if (timeNumber1 == timeNumber2 && minutData1 > minutData2) {
        this.toastr.error("please select valid Time & Minute")
        return

      }

    }

    if (time1String === 'AM' && time2String === 'AM') {
      if (this.time1 == this.time2) {
        this.toastr.warning('Start  Time & End Time shoulde not Match Choose another  Time')
        return

      }
      else if (timeNumber1 > timeNumber2) {
        this.toastr.error("please select valid time")
        return

      }
      else if (timeNumber1 == timeNumber2 && minutData1 > minutData2) {
        this.toastr.error("please select valid Time & Minute")
        return

      }

    }


    let data = {
      vendorId: this.userData?._id,
      venueLocation: this.addCityForm.value.venueLocation,
      lat: this.latitude1,
      long: this.longitude1,
      venueName: this.addCityForm.value.venueName,
      // openingTime: new Date().setHours(timeNumber1, Number(datas1 ? datas1 : minutData1)),
      // closingTime: new Date().setHours(timeNumber2, Number(datas2 ? datas2 : minutData2)),



      openingTime: new Date().setHours((time1String == 'PM'  && timeNumber1 > 12 ? timeNumber1 :time1String == 'PM'  && timeNumber1 < 12 ?(timeNumber1 + 12):time1String == 'AM' && timeNumber1 == 12 ? timeNumber1-12 : timeNumber1), Number(datas1 ? datas1 : minutData1)) ,
      closingTime: new Date().setHours((time2String == 'PM'  && timeNumber2 > 12 ? timeNumber2 :time2String == 'PM'  && timeNumber2 < 12 ?(timeNumber2 + 12):time2String == 'AM' && timeNumber2 == 12 ? timeNumber2-12 : timeNumber2), Number(datas2 ? datas2 : minutData2)) 


      // openingTime: new Date().setHours((time1String == 'PM' ? (timeNumber1 + 12) : timeNumber1), Number(datas1 ? datas1 : minutData1)) ,
      // closingTime: new Date().setHours((time2String == 'PM' ? (timeNumber2 + 12) : timeNumber2), Number(datas2 ? datas2 : minutData2)) 


      // new Date(this.venueMgmtForm.value.endDate).setHours((time2String == 'PM' ? (timeNumber2 + 12) : timeNumber2), Number(datas2 ? datas2 : minutData2)) 
      }

      if(data.openingTime)
        data.openingTime = this.convertDateTOUtc(data.openingTime);
      if(data.closingTime)
        data.closingTime = this.convertDateTOUtc(data.closingTime);


      this.api.post('add_venue', data).subscribe({
        next: (value: any) => {
          this.toastr.success(value.message)
          this.router.navigateByUrl('/profile-creation');
        }, error: (err: any) => {
          console.log(err)
          this.toastr.error(err.error.message)
        },

      })
}

  convertDateTOUtc(date){
    let utc = new Date(date).getTime() - ( new Date(date).getTimezoneOffset() * 60000);
    return utc;

    // let utc = new Date(date).getTime() + (new Date(date).getTimezoneOffset() * 60000);
    // return utc;
  }

  get a() {
    return this.addCityForm.controls;
  }
  onKeyPress(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.keyCode);
    const pattern = /^[a-zA-Z\s]*$/;
    // pattern=".*[^A-Za-z ].*"

    if (!pattern.test(inputChar)) {
      event.preventDefault();
    }
  }


  keyDownFunction(event) {
    if (event.keyCode === 13) {
      // rest of your code
    }
  }
}

