import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class GetSetValueService {

  constructor() { }


  setData:any
  dataSetter(data:any) {
    this.setData = data;
  }
  dataGetter() {
    return this.setData;
  }

  getLocalLanguage(){
    let lan = localStorage.getItem('language');
    if(!lan){
      localStorage.setItem('language',"english");
      lan = "english";
    }
    return lan;
  }

}
