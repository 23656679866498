import { Injectable } from '@angular/core';
import { Subject, BehaviorSubject, Observable,ReplaySubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SharedServiveService {

  constructor() { }

  public editData: any = [];
  public subject = new Subject<any>();
  private messageSource = new  BehaviorSubject(this.editData);
  currentMessage = this.messageSource.asObservable();
  changeMessage(message: any) {
  this.messageSource.next(message)
}
}
