<app-authentication-header></app-authentication-header>
<section class="login d-flex align-items-center justify-content-center">
    <div class="login-box">
        <div class="row">
            <div class="col-lg-6">
                <img class="sports-img" src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/login_logo_with_bg.png"
                    alt="">
            </div>
            <div class="col-lg-6 login-text">
                <form [formGroup]='OtpForm'>
                    <!-- <img class="w-50" src="assets/img/main-logo.png" alt=""> -->
                    <h4>{{showData?.ForgotPassword}}</h4>
                    <p>{{showData?.registered}}</p>
                    <div class="login-input mt-4">
                        <label for="email">{{showData?.RegisteredEmailID}}</label>
                        <mat-form-field class="example-full-width">
                            <input matInput placeholder="{{showData?.EnterYourRegistered}}" formControlName = 'email'>
                        </mat-form-field>
                        <div *ngIf="submitted && f['email'].errors" class="text-danger">
                            <div *ngIf="f['email'].errors['required']">
                              {{showData?.Pleaseenteremail}}
                            </div>
                        </div>
                    </div>
                    <!-- <div class="forget text-center">
                        <p>Didn't receive code?<span class="font-green"> Resent OTP</span></p>
                    </div> -->
                    <div class="sub-log">
                        <button class="login-btn"  autofocus (click)="forgetpass()"  (keydown)="keyDownFunction($event)">{{showData?.otp}}</button>
                    </div>
                </form>

            </div>
        </div>
    </div>
</section>
