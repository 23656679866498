import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/service/api.service';
import { GetSetValueService } from 'src/app/service/get-set-value.service';
import { SessionstorageService } from 'src/app/service/sessionstorage.service';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css'],
})
export class ResetPasswordComponent {
  submitted: Boolean = false;
  hide = true;
  hide1 = true;
  paramId: any;
  accessToken: any;
  emailCheck: any;

  constructor(
    private api: ApiService,
    private toastr: ToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private sessionStorage: SessionstorageService,
    private localLan: GetSetValueService,
  ) {}

  ngOnInit() {
    this.language = this.localLan.getLocalLanguage();
    this.setLanguageData();
    this.activatedRoute.params.subscribe((params: any) => {
      this.accessToken = params?.token;
      this.sessionStorage.setLocalData('vendor-service', {
        access_token: this.accessToken,
      });
      console.log(this.accessToken, '****');
    });

    this.emailCheck = this.sessionStorage.getLocalData('Vendor_email');
    this.emailCheck = this.emailCheck?.email;
  }
  resetForm = new FormGroup({
    password: new FormControl('', [
      Validators.required,
      Validators.pattern(
        /^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).{8,16}$/
      ),
    ]),
  });

  get f() {
    return this.resetForm.controls;
  }
  resetPassClick() {
    this.submitted = true;
    if (this.resetForm.invalid) {
      return;
    }
    let data = this.resetForm.value;
    this.api.post('reset_password', data).subscribe({
      next: (value: any) => {
        this.toastr.success(value.message);
        sessionStorage.removeItem('vendor-service');
        sessionStorage.removeItem('Vendor_email');
        this.router.navigateByUrl('/login');
      },
      error: (err: any) => {
        this.toastr.error(err.error.message);
      },
    });
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      // rest of your code
    }
  }

  language: any;
  showData: any;

  languageEng = {
    ResetPassword: 'Reset Password',
    CreatePassword:'Create Password',
    hurry: 'Hurray! You are all set, Now you can create your new password.',
    NewPassword: 'New Password',
    show:"show",
    hide:'hide',
    PleaseEnterNewpassword: 'Please Enter New password',
    must:'Password must be alphabet special  character & numeric at least 1 uppercase value with minimum 8 digit',
    reset: 'Reset Password & Sign In',
    continue:'Continue to Login'

  };

  languageArabic = {
    ResetPassword: 'إعادة تعيين كلمة المرور',
    CreatePassword:'إنشاء كلمة المرور',
    hurry: 'يا هلا! لقد انتهيت من كل شيء، والآن يمكنك إنشاء كلمة المرور الجديدة الخاصة بك.',
    NewPassword: 'كلمة المرور الجديدة',
    show:"عرض",
    hide:"يخفي",
    PleaseEnterNewpassword: 'الرجاء إدخال كلمة المرور الجديدة',
    must:'يجب أن تكون كلمة المرور عبارة عن حرف أبجدي خاص ورقم على الأقل بقيمة حرف كبير واحد مع 8 أرقام على الأقل',
    reset: 'إعادة تعيين كلمة المرور وتسجيل الدخول',
    continue:'الاستمرار في تسجيل الدخول'
  };

  setLanguageData() {
    if (this.language === 'english') {
      this.showData = this.languageEng;
    } else {
      this.showData = this.languageArabic;
      var elements1 = document.querySelectorAll('.login-text');
      elements1.forEach((element1) => {
        element1.classList.add('rtl-direction');
      });
    }
  }
}
