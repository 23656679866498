
<section class="login d-flex align-items-center justify-content-center">
    <div class="login-box">
        <div class="row">
            <div class="col-lg-6">
                <img class="sports-img" src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/login_logo_with_bg.png" alt="">
            </div>
            <div class="col-lg-6 login-text">
                <form [formGroup] =  "otpForm"  (keydown)="keyDownFunction($event)">
                <!-- <img class="w-50" src="assets/img/main-logo.png" alt=""> -->
                <h4>Account Verification</h4>
                <p>We have just sent you an 4-digit code on your email <span class="font-blue"> {{getSessionData?.email}}</span></p>
                <div class="login-input mb-2 ">
                    <label for="email">Verification Code</label>
                        <mat-form-field class="example-full-width">
                            <input matInput placeholder="Enter 4-digit code here" maxlength="4" formControlName = "otp" onkeypress="return event.charCode > 47 && event.charCode < 58;" onpaste="return false;" ondrop="return false;" >
                        </mat-form-field>
                        <div *ngIf="submitted && f['otp'].errors" class="text-danger">
                            <div *ngIf="f['otp'].errors['required']">
                              Please enter otp
                            </div>
                        </div>
                    </div>
                    <div class="forget text-center mt-1">

         <div class="otp-sec mt-1 " *ngIf="counter!==0">  <i class="bi bi-clock-fill"></i><span> 00:{{getCounterData(counter)}} {{!secondValue ? 'Seconds' : 'Second'}}</span></div>
                        <!-- <div  (click)="resendOtp()" class="otp-sec otp-bt" *ngIf="counter==0"> <span> Resend OTP</span></div> -->
                        <p  *ngIf="counter ==0"><span class="font-black"  style="margin-top:5px !important;">Didn't receive code?</span><a (click)="resendOtp()" style="cursor: pointer; color: #91cc02;" > Resend Code</a><span class="font-green point"></span></p>


                    </div>

                    <div class="sub-log mt-1">
                        <button class="login-btn"  autofocus (click) = "otp()">Verify </button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
