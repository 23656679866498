import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SessionstorageService {
  constructor() {}

  // Get environment baseUrl

  baseUrl = environment.baseUrl;

  public setLocalData(key: string, value: any) {
    sessionStorage.setItem(key, JSON.stringify(value));
  }

  public getLocalData(key: string) {
    let data = sessionStorage.getItem(key);
    if (data == null || data == 'undefined') {
      return null;
    }
    return JSON.parse(data);
  }
}
