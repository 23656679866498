import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { catchError, Observable, throwError } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService {

  constructor(private router: Router) { }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let userData = sessionStorage.getItem('vendor-service');
    let lang = localStorage.getItem('language');
    console.log('==========================',lang)
    var access_token;
    if (userData && userData != 'undefined') {
      // access_token = JSON.parse(userData).access_token;
      access_token = JSON.parse(userData).access_token;
      // console.log(access_token,"HY SHUBH")
      if (!access_token) {
        access_token = '';
      }
    } else {
      access_token = '';
    }
    let url = '';

    url = req.url;
    // console.log(url)
    // access_token =
    //   'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJtb2JpbGVfbnVtYmVyIjoiOTk5OTk5OTkxMiIsImlhdCI6MTY4MzU0MDA5NX0.GdNCjFN8yJ0sT-bZhvvdVbZzqKEgnhnwI8Q_fEjvH-Q';
    const copiedReq = req.clone({
      headers: req.headers
      .append('access_token', access_token)
        .append('lang', lang),
      url: url,
    });

    return next.handle(copiedReq).pipe(
      catchError((error: HttpErrorResponse) => {
        if (
          error.error &&
          (error.error.message == 'Invalid access_token.' ||
            error.error.message == 'Access token missing')
        ) {
          sessionStorage.removeItem('vendor-service');
          this.router.navigate(['/log-in']);
          window.location.reload();
        }
        return throwError(error);
      })
    );
  }
}
