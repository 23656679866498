import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/service/api.service';
import { GetSetValueService } from 'src/app/service/get-set-value.service';
import { SessionstorageService } from 'src/app/service/sessionstorage.service';

@Component({
  selector: 'app-verify-otp',
  templateUrl: './verify-otp.component.html',
  styleUrls: ['./verify-otp.component.css'],
})
export class VerifyOtpComponent {
  submitted: Boolean = false;
  getSessionData: any;
  otpRes: any;
  constructor(
    private api: ApiService,
    private toastr: ToastrService,
    private router: Router,
    private sessionStorage: SessionstorageService,
    private localLan: GetSetValueService,
  ) {}
  otpForm = new FormGroup({
    otp: new FormControl('', Validators.required),
  });
  get f() {
    return this.otpForm.controls;
  }
  ngOnInit() {
    this.language = this.localLan.getLocalLanguage();
    this.setLanguageData();
    this.getSessionData = this.sessionStorage.getLocalData('vendor-service');
    // console.log(this.getSessionData,"****")
    this.timer();
    this.getEmail();
  }

  emailDatas: any;
  getEmail() {
    this.emailDatas = sessionStorage.getItem('Vendor_email');
    this.emailDatas = JSON.parse(this.emailDatas);
  }
  otp() {
    this.submitted = true;
    if (this.otpForm.invalid) {
      return;
    }
    let data = {
      otp: this.otpForm.value.otp,
      type: 2,
    };
    this.api.post('verify_otp', data).subscribe({
      next: (value: any) => {
        this.otpRes = value.response;
        this.toastr.success('OTP is verified.');
        this.sessionStorage.setLocalData('vendor-service', this.otpRes);
        this.router.navigateByUrl(
          `/reset-password/${this.otpRes?.access_token}`
        );
      },
      error: (err: any) => {
        console.log(err.error.message);
        this.toastr.error(err.error.message);
      },
    });
  }
  resendOtp() {
    this.api.get('resend_otp').subscribe({
      next: (res: any) => {
        this.timer();
        this.otpForm.reset();
        this.toastr.success(res.message);
      },
      error: (err: any) => {
        this.toastr.error(err.error.message);
      },
    });
  }

  counter: number = 0;
  secondValue: boolean = false;
  timer() {
    console.log('****** time');
    this.counter = 59;
    let startTimer = setInterval(() => {
      this.counter = this.counter - 1;
      if (this.counter == 1) {
        this.secondValue = true;
        console.log(this.secondValue, 'second value');
      }
      if (this.counter == 0) {
        this.secondValue = false;

        window.clearInterval(startTimer);
      }
    }, 1000);
  }
  getCounterData(counter: number) {
    let returnCounter = `${counter}`;
    if (returnCounter.length == 1) {
      return `0${this.counter}`;
    }
    return this.counter;
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      // rest of your code
      console.log('enter key');
    }
  }

  language: any;
  showData: any;

  languageEng = {
    AccountVerification: 'Account Verification',
    codeMsg: 'We have just sent you an 4-digit code on your email',
    VerificationCode: 'Verification Code',
    Entercodehere: 'Enter 4-digit code here',
    enterOtp: 'Please enter otp',
    receiveCode: "Didn't receive code?",
    ResendCode: 'Resend Code',
    Verify: 'Verify',
    Seconds: 'Seconds',
    Second: 'Second',
  };

  languageArabic = {
    AccountVerification: 'تأكيد الحساب',
    codeMsg: 'لقد أرسلنا للتو رمزًا مكونًا من 4 خانات إلى بريدك الإلكتروني',
    VerificationCode: 'رمز التأكيد',
    Entercodehere: 'أدخل هنا الرمز المكون من 4 خانات',
    enterOtp: 'الرجاء إدخال كلمة المرور',
    receiveCode: 'لم تتلق الرمز؟',
    ResendCode: ' إعادة إرسال الرمز',
    Verify: 'تأكيد',
    Seconds: 'ثانية',
    Second: 'ثوانٍ',
  };

  setLanguageData() {
    if (this.language === 'english') {
      this.showData = this.languageEng;
    } else {
      this.showData = this.languageArabic;
      var elements1 = document.querySelectorAll('.login-text');
      elements1.forEach((element1) => {
        element1.classList.add('rtl-direction');
      });
    }
  }
}
