<app-authentication-header></app-authentication-header>
<section class="login d-flex align-items-center justify-content-center">
    <div class="login-box">
        <div class="row">
            <div class="col-lg-6">
                <img class="sports-img" src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/login_logo_with_bg.png" alt="">
            </div>
            <div class="col-lg-6 login-text">
                <form [formGroup] = "resetForm">
                    <!-- <img class="w-50" src="assets/img/main-logo.png" alt=""> -->
                    <h4 *ngIf="emailCheck">{{showData?.ResetPassword}}</h4>
                    <h4 *ngIf="!emailCheck"> {{showData?.CreatePassword}}</h4>

                    
                    <p>{{showData?.hurry}}</p>
                    <div class="login-input mt-3">
                        <label for="email">{{showData?.NewPassword}}</label>
                        <mat-form-field class="example-full-width">
                            <input matInput [type]="hide ? 'password' : 'text'" placeholder="{{showData?.NewPassword}}" formControlName = 'password'>
                            <div class="my-show" matSuffix (click)="hide = !hide" [attr.aria-label]="'Hide password'" [attr.aria-pressed]="hide">
                                <span style="cursor: pointer;"> {{hide ? showData.show : showData.hide}} </span>
                             </div>
                        </mat-form-field>
                        <div class="text-danger" *ngIf="submitted && f.password.errors">
                            <div *ngIf="submitted && f.password.errors['required']">{{showData?.PleaseEnterNewpassword}}</div>
                            <div *ngIf="f.password.errors['pattern']">{{showData?.must}}</div>
                        </div>
                    </div>
                    <div class="sub-log">
                        <button class="login-btn" *ngIf="emailCheck"  autofocus (click) = "resetPassClick()" (keydown)="keyDownFunction($event)">{{showData?.reset}}</button>
                        <button class="login-btn"  *ngIf="!emailCheck" autofocus (click) = "resetPassClick()" (keydown)="keyDownFunction($event)">{{showData?.continue}}</button>

                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
