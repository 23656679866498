<!-- <p>header works!</p> -->
<div class="d-flex nav-talks align-items-center">
  <img
    src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/hdr_notification.png"
    alt=""
  />
  <!-- <img src="./../../../assets/img/Sportsbay_Vendor_Web_Panel_Assets/hdr_language.png"> -->
  <div class="header-section">
    <img
      class="img-fluid"
      [matMenuTriggerFor]="menu"
      src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/hdr_language.png"
      alt=""
    />
    <mat-menu class="lang-menu" #menu="matMenu">
      <button
        [ngClass]="{ selected: language === 'english' }"
        (click)="changeLanguage('english')"
        mat-menu-item
      >
        English
      </button>

      <button
        [ngClass]="{ selected: language === 'arabic' }"
        (click)="changeLanguage('arabic')"
        mat-menu-item
      >
        اَلْعَرَبِيَّةُ
      </button>
    </mat-menu>
    <!-- <img routerLink="/login" src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/hdr_logout.png" alt=""> -->
  </div>
  <img
    (click)="logout()"
    src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/hdr_logout.png"
    alt=""
  />

  <!-- <img  src="./.././../../assets/img/Sportsbay_Vendor_Web_Panel_Assets/hdr_profile_placeholder.png" routerLink="/home/profile-update"> -->
  <img
    *ngIf="!userData?.profile_image"
    src="./.././../../assets/img/Sportsbay_Vendor_Web_Panel_Assets/hdr_profile_placeholder.png"
    routerLink="/home/profile-update"
  />
  <!-- <img  *ngIf ="userData?.profile_image && userData?.isSubVendor == 0 " src="{{userData?.profile_image}}" routerLink="/home/profile-update" style="border-radius: 25px;"> -->

  <img
    *ngIf="userData?.profile_image && userData?.isSubVendor == 0"
    src="{{ userData?.profile_image }}"
    (click)="addClick(userData?.profile_image && userData?.isSubVendor == 0)"
    style="border-radius: 25px"
  />
</div>
