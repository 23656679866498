import { ElementRef, Injectable, NgZone, ViewChild } from '@angular/core';
import { MapsAPILoader } from '@agm/core';
declare var google: any;

@Injectable({
  providedIn: 'root',
})
export class GetCurrentLocationService {
  public lat: any;
  public lng: any;

  latitude: any;
  longitude: any;
  zoom: any;
  address: any;
  private geoCoder: any;
  // mapsAPILoader: any;
  @ViewChild("search") public searchElementRef!: ElementRef | any;

  constructor(
    private ngZone: NgZone,
    private mapsAPILoader: MapsAPILoader,

  ) {}

  getLocation() {
    return new Promise((resolve, reject) => {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position: any) => {
            if (position) {
              // console.log("Latitude: " + position.coords.latitude + " " +
              //   "Longitude: " + position.coords.longitude);
              this.lat = position.coords.latitude;
              this.lng = position.coords.longitude;

              // console.log(this.lat);
              // console.log(this.lat);
              let returnValue = {
                lat: this.lat,
                lng: this.lng,
              };
              resolve(returnValue);
            }
          },
          (error: any) => reject(error)
        );
      } else {
        alert('Geolocation is not supported by this browser.');
        reject('Geolocation is not supported by this browser.');
      }
    });
  }
}
