import { Component } from '@angular/core';

@Component({
  selector: 'app-document-uplode',
  templateUrl: './document-uplode.component.html',
  styleUrls: ['./document-uplode.component.css']
})
export class DocumentUplodeComponent {

  constructor(){

  }
   userData:any
   ngOnInit()
{

   this.userData =  sessionStorage.getItem('vendor-service')
    this.userData = JSON.parse(this.userData)
     console.log(this.userData,"*****")

    

  
}    

}
