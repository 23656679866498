import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/service/api.service';
import { SessionstorageService } from 'src/app/service/sessionstorage.service';
import { environment } from 'src/environments/environment';
 declare   var $:any



@Component({
  selector: 'app-profile-creation',
  templateUrl: './profile-creation.component.html',
  styleUrls: ['./profile-creation.component.css'],
})
export class ProfileCreationComponent {
  trade_license_back: any;
  id_front: any;
  id_back: any;
  submitted: Boolean = false;
  LicenseFront: any;
  LicenseBack: any;
  IdFront: any;
  Idback: any;
  trade_license_front: any;
 

  constructor(
    private api: ApiService,
    private toastr: ToastrService,
    private router: Router,
    private sessionStorage: SessionstorageService
  ) { }

  userData:any
 ngOnInit(){
this.userData = sessionStorage.getItem('vendor-service')
 this.userData= JSON.parse(this.userData)
  if(this.userData?.rejected_reason!=null)
  this.patchVelueProfileDetails(this.userData)


 }

  url1 = 'assets/img/upload_img_front.png';
  url2 = 'assets/img/upload.png';
  url3 = 'assets/img/upload_img_front.png';
  url4 = 'assets/img/upload.png';
  tradeLicenseFrontImage:any
  tradeLicenseBackImage:any
   idProodFrontImage:any
   idProodBackImage:any


  baseUrl = environment.baseUrl

  createProfileForm = new FormGroup({
    trade_license_front: new FormControl(''),
    trade_license_back: new FormControl(''),
    id_front: new FormControl(''),
    id_back: new FormControl(''),
    iban: new FormControl('', [Validators.required]),
    bank_name: new FormControl('', [Validators.required]),
    swift_code: new FormControl('', [Validators.required]),
    account_holder_name: new FormControl('', [Validators.required]),
    account_holder_address: new FormControl('', [Validators.required]),
    trn_no: new FormControl(''),
  });


  
  // ......for upload TradeLicenseFront...........
  uploadTradeLicenseFront(event: any) {
    this.LicenseFront = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url
      this.uploadImage1(this.LicenseFront);

      reader.onload = (event: any) => {
        // called once readAsDataURL is completed
        this.url1 = event.target.result;
      };
    }
  }

  uploadImage1(img: any) {
    let data = new FormData();
    data.append("upload_vendor_file", this.LicenseFront);
    this.api.post("upload_file", data).subscribe((res: any) => {
      this.tradeLicenseFrontImage = res.response;

       console.log(this.tradeLicenseFrontImage,"tradeLicenseFrontImage")
    });
    this.createProfileForm.patchValue({
      trade_license_front: this.url1,
    });
  }
  // ........For TradeLicense Back..........
  uploadTradeLicenseBack(event: any) {
    this.LicenseBack = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();
      reader.readAsDataURL(event.target.files[0]); // read file as data url
      this.uploadImage2(this.LicenseBack);

      reader.onload = (event: any) => {
        // called once readAsDataURL is completed
        this.url2 = event.target.result;
      };
    }
  }


  uploadImage2(img: any) {
    let data = new FormData();
    data.append("upload_vendor_file", this.LicenseBack);
    this.api.post("upload_file", data).subscribe((res: any) => {
      this.tradeLicenseBackImage = res.response;
      console.log(this.tradeLicenseBackImage,"tradeLicenseBackImagetradeLicenseBackImagetradeLicenseBackImagetradeLicenseBackImage")
    });
    this.createProfileForm.patchValue({
      trade_license_back: this.url2,
    });
  }

  // .........For UploadID Front...............
  uploadIdFront(event: any) {
    this.IdFront = event.target.files[0];
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.readAsDataURL(event.target.files[0]); // read file as data url
      this.uploadImage3(this.IdFront);
      reader.onload = (event: any) => {
        // called once readAsDataURL is completed
        this.url3 = event.target.result;
      };
    }
  }


  uploadImage3(img: any) {
    let data = new FormData();
    data.append("upload_vendor_file", this.IdFront);
    this.api.post("upload_file", data).subscribe((res: any) => {
      this.idProodFrontImage = res.response;
       console.log(this.idProodFrontImage,"****idProodFrontImage")
    });
    this.createProfileForm.patchValue({
      id_front: this.url3,
    });
  }

  // ........For upload ID Back............
  uploadIdEnd(event: any) {
    this.Idback = event.target.files[0];

    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      // read file as data url
      reader.readAsDataURL(event.target.files[0]);
      this.uploadImage4(this.Idback);
      reader.onload = (event: any) => {
        // called once readAsDataURL is completed
        this.url4 = event.target.result;
      };
    }
  }


  uploadImage4(img: any) {
    let data = new FormData();
    data.append("upload_vendor_file", this.Idback);
    this.api.post("upload_file", data).subscribe((res: any) => {
      this.idProodBackImage = res.response;
      //  console.log(this.idProodBackImage,"******")
    });
    this.createProfileForm.patchValue({
      id_back: this.url4,
    });
  }

  get a() {
    return this.createProfileForm.controls;
  }

  onKeyPress(event: KeyboardEvent) {
    const inputChar = String.fromCharCode(event.keyCode);
    const pattern = /^[a-zA-Z\s]*$/;
if (!pattern.test(inputChar)) {
        event.preventDefault();
    }
}

onKeyPress2(event: KeyboardEvent) {
  const inputChar = String.fromCharCode(event.keyCode);
  const pattern = /^[a-z0-9\.-@_s]/;

  if (!pattern.test(inputChar)) {
      event.preventDefault();
  }
}

onKeyPress1(event: KeyboardEvent) {
  if (event.key === ' ') {
    event.preventDefault();
  }
}

completeProfileMethod() {
    this.submitted = true;
    
    if (this.createProfileForm.invalid) {
      return
    }

  if(this.tradeLicenseFrontImage == null && this.tradeLicenseBackImage == null){
      this.toastr.warning("Please  upload at  least  front  or  back  Trade License Image")
      return

    }
     if(this.idProodFrontImage == null && this.idProodBackImage == null){
      this.toastr.warning("Please  upload at  least  front  or  back  Id proof image")
      return}

    let data = this.createProfileForm.value;

    data.trade_license_front = this.tradeLicenseFrontImage,
    data.trade_license_back = this.tradeLicenseBackImage,
    data.id_front = this.idProodFrontImage,
    data.id_back = this.idProodBackImage,

    this.api.post("update_profile", data).subscribe({
      next: (res: any) => {
        this.toastr.success(res.message);
      this.router.navigateByUrl('/admin-aproval');
      },
      error(err) {
        console.log(err.error.message);
      },
    });
}


onlyAlphaNumForIban(event:any) {
 document.getElementById('iban').addEventListener('input', function (e) {
   event.target.value = event.target.value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
 });

}

mychange(val:any) {
   console.log(val,"::::")
  const self = this;
  let chIbn = val.split('').join('');
  if (chIbn.length > 0) {
    chIbn = chIbn.match(new RegExp('.{1,4}', 'g')).join('');
  }
}

onlyAlphaNum(event: KeyboardEvent) {
  const inputChar = String.fromCharCode(event.keyCode);
  const pattern = /^[a-zA-Z0-9\s]*$/;

  if (!pattern.test(inputChar)) {
    event.preventDefault();
  }
}

onlyAlphaNumSwiptCode(event: KeyboardEvent) {
  const inputChar = String.fromCharCode(event.keyCode);
  const pattern = /^[a-zA-Z0-9\s]*$/;

  if (!pattern.test(inputChar)) {
    event.preventDefault();
  }
}

onlyAlpha(event: KeyboardEvent) {
  const inputChar = String.fromCharCode(event.keyCode);
  const pattern = /^[A-Za-z\s]*$/;

  if (!pattern.test(inputChar)) {
    event.preventDefault();
  }
}

onlyAlphaForTrn(event: KeyboardEvent) {
  const inputChar = String.fromCharCode(event.keyCode);
  const pattern = /^[0-9\s]*$/;

  if (!pattern.test(inputChar)) {
    event.preventDefault();
  }
}

onlyAlphaNumIBAN(event:any) {
document.getElementById('iban').addEventListener('input', function (e) {
  event.target.value = event.target.value.replace(/[^\da-zA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();

 });


}

keyDownFunction(event) {
  if (event.keyCode === 13) {
    // rest of your code
  }
}
patchVelueProfileDetails(userData:any){
  this.createProfileForm.patchValue({
    iban:userData.bank_details?.iban,
    bank_name: userData?.bank_details?.bank_name,
    swift_code: userData.bank_details?.swift_code,
    account_holder_name: userData?.bank_details?.account_holder_name,
    account_holder_address:userData?.bank_details?.account_holder_address,
    trn_no:userData?.bank_details?.trl_no 
})
  this.tradeLicenseFrontImage= userData?.upload_trade_license?.trade_license_front
    this.tradeLicenseBackImage=userData?.upload_trade_license?.trade_license_back
    this.idProodFrontImage= userData?.upload_id?.id_front
    this. idProodBackImage=userData?.upload_id?.id_back


  }


  addTrn(event:any){

    document.getElementById('address').addEventListener('keydown', function(event) {
      if (event.key === 'Enter') {
          event.preventDefault();
      }
  })}
}
