import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/service/api.service';
import { SessionstorageService } from 'src/app/service/sessionstorage.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { GetSetValueService } from 'src/app/service/get-set-value.service';

@Component({
  selector: 'app-forget-password',
  templateUrl: './forget-password.component.html',
  styleUrls: ['./forget-password.component.css'],
})
export class ForgetPasswordComponent {
  hide = true;
  submitted: Boolean = false;
  constructor(
    private api: ApiService,
    private router: Router,
    private toastr: ToastrService,
    private sessionStorage: SessionstorageService,
    private localLan: GetSetValueService,

  ) {}

  OtpForm = new FormGroup({
    email: new FormControl('', Validators.required),
  });

  get f() {
    return this.OtpForm.controls;
  }

  sendOTP: any;
  forgetpass() {
    console.log('hi');

    let data = this.OtpForm.value;
    this.api.post('forget_password', data).subscribe({
      next: (value: any) => {
        this.toastr.success(value.message);
        this.sendOTP = value.response;
        this.sessionStorage.setLocalData('vendor-service', this.sendOTP);
        sessionStorage.setItem(
          'Vendor_email',
          JSON.stringify({ email: this.OtpForm.value.email })
        );

        this.router.navigateByUrl('/verify-otp-verification');
        // console.log(value,"OTP Send Successfully")
      },
      error: (err) => {
        this.toastr.error(err.error.message);
        console.log(err);
      },
    });
  }

  keyDownFunction(event) {
    if (event.keyCode === 13) {
      // rest of your code
    }
  }

  ngOnInit(): void {
    this.language = this.localLan.getLocalLanguage();
    this.setLanguageData();
  }

  language: any;
  showData: any;

  languageEng = {
    ForgotPassword: "Forgot Password",
    registered:'Enter your registered email address below, we will send you a 4 -digit code to verification.',
    RegisteredEmailID:'Registered Email ID',
    EnterYourRegistered: 'Enter Your Registered Email Id ',
    otp:'Send OTP',
    Pleaseenteremail:'Please enter email'
  };

  languageArabic = {

    ForgotPassword: "هل نسيت كلمة السر",
    registered:'أدخل عنوان بريدك الإلكتروني المسجل أدناه، وسنرسل لك رمزًا مكونًا من 4 أرقام للتحقق.',
    RegisteredEmailID:'معرف البريد الإلكتروني المسجل',
    EnterYourRegistered: 'أدخل معرف البريد الإلكتروني المسجل الخاص بك ',
    otp:'أرسل كلمة مرور لمرة واحدة',
    Pleaseenteremail:'الرجاء إدخال البريد الإلكتروني'


  };

  setLanguageData() {
    debugger;
    if (this.language === 'english') {
      this.showData = this.languageEng;
    } else {
      this.showData = this.languageArabic;

      var elements1 = document.querySelectorAll('.login-text');

      elements1.forEach((element1) => {
        element1.classList.add('rtl-direction');
      });
    }
  }
}
