<div class="aside">
  <img
    class="img-fluid"
    src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/header_logo_white.png"
    alt=""
  />
  <ul class="as-ul">
    <li routerLink="/home/dashboard" routerLinkActive="active" class="d-flex">
      <img
        class="without-hover"
        src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/sp_dashboard.png"
        alt=""
      />
      <img
        class="with-hover"
        src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/sp_dashboard_active.png"
        alt=""
      />
      {{showData?.Dashboard}}
    </li>

    <li
      routerLink="/home/booking-mgmt"
      routerLinkActive="active"
      class="d-flex"
      *ngIf="
        userData?.isSubVendor == 0 ||
        (userData?.isSubVendor == 1 &&
          userData?.moduleAccess?.is_booking_mgmt == true)
      "
    >
      <img
        class="without-hover"
        src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/sp_my_bookings.png"
        alt=""
      />
      <img
        class="with-hover"
        src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/sp_my_bookings_active.png"
        alt=""
      />{{showData?.BookingManagement}}
    </li>
    <!-- <li routerLink="/home/bookings" routerLinkActive="active" class="d-flex">
            <img class="without-hover" src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/sp_my_bookings.png" alt="">
            <img class="with-hover" src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/sp_my_bookings_active.png" alt="">
            My Bookings
        </li> -->
    <li
      class="d-flex"
      routerLink="/home/venue-mgmt"
      routerLinkActive="active"
      *ngIf="
        userData?.isSubVendor == 0 ||
        (userData?.isSubVendor == 1 &&
          userData?.moduleAccess?.is_venue_mgmt == true)
      "
    >
      <img class="without-hover" src="assets/img/venue_management.png" alt="" />
      <img
        class="with-hover"
        src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/sp_more_active.png"
        alt=""
      />{{showData?.Venuemanagement}}
    </li>

    <li
      routerLink="/home/playground-management"
      routerLinkActive="active"
      class="d-flex"
      is_payment_mgmt
      *ngIf="
        userData?.isSubVendor == 0 ||
        (userData?.isSubVendor == 1 &&
          userData?.moduleAccess?.is_sport_mgmt == true)
      "
    >
      <img
        class="without-hover"
        src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/sp_playground_mgt.png"
        alt=""
      />
      <img
        class="with-hover"
        src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/sp_playground_mgt_active.png"
        alt=""
      />{{showData?.SportsGroundManagement}}
    </li>

    <!-- <li class="d-flex" routerLink="/home/offer-mgmt"  routerLinkActive="active" *ngIf="userData?.isSubVendor == 0 || userData?.isSubVendor ==1 ||checkPermission('is_offer_mgmt')">
            <img class="without-hover" src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/sp_offer_mgt.png" alt="">
            <img class="with-hover" src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/sp_more_active.png" alt="">Offer management
        </li> -->

    <li
      routerLink="/home/offer-mgmt"
      routerLinkActive="active"
      class="d-flex"
      *ngIf="
        userData?.isSubVendor == 0 ||
        (userData?.isSubVendor == 1 &&
          userData?.moduleAccess?.is_offer_mgmt == true)
      "
    >
      <img
        class="without-hover"
        src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/sp_offer_mgt.png"
        alt=""
      />
      <img
        class="with-hover"
        src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/sp_offer_mgt_active.png"
        alt=""
      />{{showData?.offerManagement}}
    </li>

    <li class="d-flex" routerLink="/home/rating-mgmt" routerLinkActive="active">
      <img
        class="without-hover"
        src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/sp_review_ratings.png"
        alt=""
      />
      <img
        class="with-hover"
        src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/sp_review_ratings_active.png"
        alt=""
      />{{showData?.Ratingmanagement}}
    </li>

    <li
      routerLink="/home/sub-owner"
      routerLinkActive="active"
      class="d-flex"
      *ngIf="userData?.isSubVendor == 0"
    >
      <img
        class="without-hover"
        src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/sp_playground_mgt.png"
        alt=""
      />
      <img
        class="with-hover"
        src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/sp_playground_mgt_active.png"
        alt=""
      />{{showData?.UserSubAdminMnagement}}
    </li>

    <li
      class="d-flex"
      routerLink="/home/payment-mgtm"
      routerLinkActive="active"
      *ngIf="
        userData?.isSubVendor == 0 ||
        (userData?.isSubVendor == 1 &&
          userData?.moduleAccess?.is_payment_mgmt == true)
      "
    >
      <img
        class="without-hover"
        src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/sp_payment_mgt.png"
        alt=""
      />
      <img
        class="with-hover"
        src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/sp_payment_mgt_active.png"
        alt=""
      />{{showData?.PaymentManagement}}
    </li>

    <!-- <li class="d-flex"> 
            <img class="without-hover" src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/sp_more.png" alt="">
            <img class="with-hover" src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/sp_more_active.png" alt="">More
        </li>  
        <li (click)="changepassword()" class="d-flex"> 
            <img class="without-hover" src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/sp_more.png" alt="">
            <img class="with-hover" src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/sp_more_active.png" alt="">Change Password
        </li>   -->

    <div class="accordion accordion-flush" id="accordionFlushExample">
      <div class="accordion-item">
        <h2 class="accordion-header" id="flush-headingOne">
          <li>
            <button
              class="accordion-button collapsed justify-content-between align-items-center"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#flush-collapseOne"
              aria-expanded="false"
              aria-controls="flush-collapseOne"
            >
              <mat-icon>more</mat-icon> <span class="mx-2">{{showData?.More}}</span>
            </button>
          </li>
        </h2>
        <div
          id="flush-collapseOne"
          class="accordion-collapse collapse accordian-nav"
          aria-labelledby="flush-headingOne"
          data-bs-parent="#accordionFlushExample"
        >
          <div class="accordion-body">
            <li
              class="d-flex"
              (click)="changepassword()"
              class="active1"
              routerLinkActive="active"
            >
              {{showData?.ChangePassword}}
            </li>
            <li
              class="d-flex"
              routerLink="/home/about"
              class="active1"
              routerLinkActive="active"
            >
              {{showData?.AboutSportsbay}}
            </li>
            <li
              class="d-flex"
              routerLink="/home/terms-conditions"
              class="active1"
              routerLinkActive="active"
            >
              {{showData?.TermsConditions}}
            </li>
            <li
              class="d-flex"
              routerLink="/home/privacy"
              class="active1"
              routerLinkActive="active"
            >
              {{showData?.PrivacyPolicy}}
            </li>
            <!-- <li class="d-flex" routerLink="/home/legal" routerLinkActive="active"> 

                                    Legal
                                     </li>  -->
            <li
              class="d-flex"
              routerLink="/home/help"
              class="active1"
              routerLinkActive="active"
            >
              {{showData?.Help}}
            </li>
            <li
              class="d-flex"
              routerLink="/home/faq"
              class="active1"
              routerLinkActive="active"
            >
              {{showData?.FAQ}}
            </li>
            <li
              class="d-flex"
              routerLink="/home/contact"
              class="active1"
              routerLinkActive="active"
            >
              {{showData?.ContactUs}}
            </li>
          </div>
        </div>
      </div>
    </div>
  </ul>
</div>
