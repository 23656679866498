import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthenticationService } from 'src/app/authentication.service';
import { ApiService } from 'src/app/service/api.service';
import { GetSetValueService } from 'src/app/service/get-set-value.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
   constructor(
    private router:Router,
    private authenticate: AuthenticationService,
    private localLan: GetSetValueService,

    ){

   }

    userData:any
 ngOnInit(){
   this.userData = JSON.parse(sessionStorage.getItem('vendor-service'))
    //  console.log(this.userData?.country_code)
 
    }


  logout(){
    sessionStorage.removeItem('vendor-service')
    this.authenticate.logIn = false;
    this.router.navigateByUrl('/login')
  }

  addClick(value:any){
     console.log(value,"*****")
    this.router.navigateByUrl('/home/profile-update')
  }

  language: any;




  changeLanguage(value: any) {
    this.language = value
    localStorage.setItem('language', this.language);
    window.location.reload()
  }




}
