import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AuthenticationRoutingModule } from './authentication-routing.module';
import { LoginComponent } from './login/login.component';
import { ForgetPasswordComponent } from './forget-password/forget-password.component';
import { MaterialModule } from '../material/material.module';
import { OtpComponent } from './otp/otp.component';
import { NgOtpInputModule } from  'ng-otp-input';
import { SharedModule } from '../shared/shared.module';
import { SignUpComponent } from './sign-up/sign-up.component';
import { ResetPasswordComponent } from './reset-password/reset-password.component';
import { Ng2TelInputModule } from 'ng2-tel-input';
import { ProfileCreationComponent } from './profile-creation/profile-creation.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { VerifyOtpComponent } from './verify-otp/verify-otp.component';
import {MatFormFieldModule} from '@angular/material/form-field';
import { AgmCoreModule } from '@agm/core';
import { VenueDetailsComponent } from './venue-details/venue-details.component';
import { AdminApprovalComponent } from './admin-approval/admin-approval.component';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import { DocumentUplodeComponent } from './document-uplode/document-uplode.component';
import { SettingLogoutComponent } from './setting-logout/setting-logout.component';


@NgModule({
  declarations: [
    LoginComponent,
    ForgetPasswordComponent,
    OtpComponent,
    SignUpComponent,
    ResetPasswordComponent,
    ProfileCreationComponent,
    VerifyOtpComponent,
    VenueDetailsComponent,
    AdminApprovalComponent,
    DocumentUplodeComponent,
    SettingLogoutComponent,
  ],
  imports: [
    CommonModule,
    AuthenticationRoutingModule,
    MaterialModule,
    NgOtpInputModule,
    SharedModule,
    Ng2TelInputModule,
    ReactiveFormsModule,
    FormsModule,
    MatFormFieldModule,
    AgmCoreModule,
    NgxMaterialTimepickerModule,
  ],
  schemas:  [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AuthenticationModule { }
