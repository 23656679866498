<app-authentication-header></app-authentication-header>
<section class="login d-flex align-items-center justify-content-center">
    <div class="login-box">
        <div class="row">
            <div class="col-lg-6">
                <img class="sports-img" src="assets/img/Sportsbay_Vendor_Web_Panel_Assets/login_logo_with_bg.png" alt="">
            </div>
            <div class="col-lg-6 login-text">
                <form [formGroup] =  "otpForm" (keydown)="keyDownFunction($event)">
                    <!-- <img class="w-50" src="assets/img/main-logo.png" alt=""> -->
                    <div class=" d-flex justify-content-between">
                      <h4>{{showData?.AccountVerification}}</h4>

                    </div>
                    <p>{{showData?.codeMsg}}<span class="font-blue"> {{emailDatas?.email}}</span></p>
                    <div class="login-input ">
                        <label for="email">{{showData?.VerificationCode}}</label>
                        <mat-form-field class="example-full-width">
                            <input matInput placeholder="{{showData?.Entercodehere}}" maxlength="4" formControlName = "otp" onkeypress="return event.charCode > 47 && event.charCode < 58;">
                        </mat-form-field>
                        <div  *ngIf="submitted && f['otp'].errors" class="text-danger mb-3">
                            <div *ngIf="f['otp'].errors['required']">
                                {{showData?.enterOtp}}
                            </div>
                        </div>
                    </div>

                    <div class="forget text-center mb-0">

                        <!-- <p><span class="font-black" *ngIf="counter!==0" >Didn't receive code?</span> Second 00:{{getCounterData(counter)}}<span class="font-green point"></span></p> -->
                        <div class="otp-sec mt-1 " *ngIf="counter!==0">  <i class="bi bi-clock-fill"></i><span> 00:{{getCounterData(counter)}} {{!secondValue ? showData?.Seconds : showData?.Second}}</span></div>
                        <!-- <div class="otp-sec" *ngIf="counter<10 && counter>0"><i class="bi bi-clock-fill"></i> <span>00:{{getCounterData(counter)}}Second</span></div> -->

                        <p  *ngIf="counter ==0"><span class="font-black" >{{showData?.receiveCode}}  </span> <a (click)="resendOtp()" style="cursor: pointer; color: #91cc02;" > {{showData?.ResendCode}} </a><span class="font-green point"></span></p>


                    </div>
                    <!-- <div class="forget text-center">
                        <p>Didn't receive code?<span class="font-green point" *ngIf="counter!==0" (click)="resendOtp()">  Resent OTP</span></p>
                    </div> -->
                    <div class="sub-log mt-2">
                        <button class="login-btn" autofocus (click) = "otp()"  >{{showData?.Verify}}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</section>
