import { Component, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
// import { Router } from '@angular/router';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApiService } from 'src/app/service/api.service';
import { GetSetValueService } from 'src/app/service/get-set-value.service';
declare var $:any

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.css']
})
export class ChangePasswordComponent {
hide=true;
hide1=true;
submitted:boolean  = false

constructor( private api:ApiService,
  private toaster:ToastrService,
  private router:Router,
  private localLan: GetSetValueService,
  public dialogRef: MatDialogRef<ChangePasswordComponent>,
  @Inject(MAT_DIALOG_DATA) public data: any,){}

ngOnInit(){
  this.language = this.localLan.getLocalLanguage();
  this.setLanguageData();
}

changePasswordForm = new FormGroup({
newPass : new FormControl('', [Validators.required, Validators.pattern(/^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).{8,16}$/)]),     
oldPass : new FormControl('', [Validators.required, Validators.pattern(/^(?!.*\s)(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).{8,16}$/)]),     
})




changePassword( 
) {
  this.submitted = true
  if (this.changePasswordForm.invalid) {
    return
  }
  let data = this.changePasswordForm.value
  this.api.post('change_password', data).subscribe({
    next: (value: any) => {
      this.router.navigateByUrl('/setting-logout')
      this.toaster.success(value.message)
      this.dialogRef.close()
      // $('#changepsswrd').modal('hide')
    }, error: (err: any) => {
      console.log(err)
      this.toaster.error(err.error.message)
    },
  })
}

 get  f (){
  return this.changePasswordForm.controls
 }



 language: any;
 showData: any;

 languageEng = {

  ChangePassword:'Change Password',
  CurrentPassword:'Current Password',
  PleaseEnterOldpassword:'Please Enter Old password',
  NewPassword:'New Password',
  PleaseEnterNewpassword:'Please Enter New password',
  passVal:'Old must be alphabet special character & numeric at least 1 uppercase value with minimum 8 digit',
  hide:'hide' , 
  show:'show'

 };

 languageArabic = {




  ChangePassword:'تغيير كلمة المرور',
  CurrentPassword:'كلمة المرور الحالية',
  PleaseEnterOldpassword:'يرجى إدخال كلمة المرور القديمة',
  NewPassword:'كلمة المرور الجديدة',
  PleaseEnterNewpassword:'يرجى إدخال كلمة المرور الجديدة',
  passVal:'يجب أن يكون القديم عبارة عن حرف أبجدي خاص ورقم رقمي وقيمة كبيرة واحدة على الأقل مع 8 أرقام على الأقل',
  hide:'يخفي',
  show:'يعرض'






 };

 setLanguageData() {
   if (this.language === 'english') {
     this.showData = this.languageEng;
   } else {
     this.showData = this.languageArabic;

     var elements2 = document.querySelectorAll('.main-content');
     elements2.forEach((element2) => {
       element2.classList.add('rtl-direction');
     });
   }
 }
}
